import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {

    constructor(public http: HttpClient) { }

    getOptsApplication() {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*.*'
            })
        };
        return opts;
    }

    getOptsJson() {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*.*'
            })
        };
        return opts;
    }

    getOptsJsonUtf8() {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*.*',
                Accept: 'application/json'
            })
        };
        return opts;
    }

    replaceAmpersand(field) {
        if (field.search('&') > 0) {
            field = field.replace(/&/g, '%26');
        } else if (field.search('\'') > 0) {
            field = field.replace(/'/g, '\'\'');
        }
        return field;
    }
}
