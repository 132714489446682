import {AfterViewInit, Directive,ElementRef, HostListener} from '@angular/core'
  
@Directive({ 
    selector:'autofocus'
}) 
export class AutoFocus implements AfterViewInit{ 
  
    constructor( 
        private elementRef: ElementRef 
    ){} 

    @HostListener('window:load')
    onLoad() {
      setTimeout(() => {
            this.elementRef.nativeElement.focus(); 
        }, 0);
    }    
  
    ngAfterViewInit(){ 
        setTimeout(() => {
            this.elementRef.nativeElement.focus(); 
        }, 0);
    } 
}