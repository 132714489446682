import { Pipe, PipeTransform } from '@angular/core';
import { Community } from '../models';

@Pipe({ name: 'communityName' })
export class CommunityNamePipe implements PipeTransform {
    transform(communityNumber: string, comnunityList: Community[]): string {
        if (comnunityList?.length > 0) {
            const result = comnunityList.find(element => element.communityNumber === communityNumber);
            return result?.communityName;
        }

        return null;
    }
}
