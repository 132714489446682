export class LcSuretyBondPricingAnalysis {
    bankId: number;
    bank: string;
    broker: string;
    dateIndemnityAgreement: Date;
    isActive: boolean;
    commitAmount: number;
    financialGuaranteeCommitAmount: number;
    singleBondLimit: number;
    isPowerOfAttorney?: boolean;
    amountTypes: string;
    outstandingAmount: number;
    rate: number;
    financialGuaranteeRate: number;
    minimumFee: number;
    terms: string;
    description: string;
    groupKey: number;
}
