// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .form-control-date {
    width: 100%;
}

.btn-margin {
    margin: 0px 5px;
}

.well {
    border: solid 1px #d3d3d3;
    margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/reports/invoice-by-bank-surety/invoice-by-bank-surety.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[":host ::ng-deep .form-control-date {\r\n    width: 100%;\r\n}\r\n\r\n.btn-margin {\r\n    margin: 0px 5px;\r\n}\r\n\r\n.well {\r\n    border: solid 1px #d3d3d3;\r\n    margin: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
