export class BondsFilter {
    isLetterOfCredit?: number;
    status?: number;
    bankId?: number;
    amountTypeId?: number;
    amountSubTypeId?: number;
    beneficiary?: string;
    rate?: string;
    fromAmount?: number;
    toAmount?: number;
    fromDateIssued?: Date | string;
    toDateIssued?: Date | string;
    fromDateCanceled?: Date | string;
    toDateCanceled?: Date | string;
    number: string;
    id: number;
    marketNumber?: string;
    communityNumber?: string;
}
