import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs/operators';
import { Table } from 'primeng/table';

import { CreateBankComponent } from '../create-bank/create-bank.component';

import { CommonFunctions } from '../../functions';
import { AmountType, BankAmountType, BanksFilter, BankWithAmountTypes, Broker } from '../../models';
import {
    AmountTypeService,
    AuthorizationService,
    BankAmountTypeService,
    BankService,
    BrokerService,
} from '../../services';

@Component({
    selector: 'app-search-bank',
    templateUrl: './search-bank.component.html',
    styleUrls: ['./search-bank.component.css']
})
export class SearchBankComponent implements OnInit {
    @ViewChild(CreateBankComponent) addModal: CreateBankComponent;
    @ViewChild('tableBanks', { static: true }) tableBanks!: Table;

    amountTypes: AmountType[] = [];
    banksFilterList: BanksFilter[] = null;
    banksFullList: BanksFilter[] = null;
    bankHeaderCols: any[] = [
        { field: 'name', header: 'Name', width: '40%' },
        { field: 'commitAmount', header: 'Commit Amount', width: '25%' },
        { field: 'isSurety', header: 'Type', width: '15%' },
        { field: 'isActive', header: 'Inactive', width: '10%' },
        { field: 'edit', header: 'Edit', width: '10%' }
    ];
    brokerList: Broker[] = [];
    displayAddDialog: boolean = false;
    displayEditDialog: boolean = false;
    isAddClicked: boolean = false;
    keyword: string = null;
    modifierId: number = 0;
    modifiedBy: string = 'BLC System';
    selectedBank: BankWithAmountTypes;
    selectedBankAmountTypes: string[] = [];
    showNoResultsMessage: boolean = false;
    showSearchResults: boolean = false;
    @ViewChildren('bank-surety-search-input') bankSuretySearchInput: ElementRef;

    constructor(private amountTypeService: AmountTypeService,
        private authService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private brokerService: BrokerService,
        private commonFunctions: CommonFunctions,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.authService.checkBlcUserAccess(true);
        this.getAmountTypes();
        this.getBrokers();
        this.getUser();
        //  make sure that the grid is pre populated from first page render!
        this.keyword = '';
        this.onBankSearchSubmit(false);
    }

    onNotify() {
        this.messageService.add({ severity: 'success', summary: 'Test Notification', detail: 'Testing Message' });
    }

    getAmountTypes(): void {
        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypes = result;
                this.amountTypes.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Amount Types Not Loaded',
                    detail: 'Please be advised, unable to load Amount Types.  Nothing to bind Amount Type checkboxes with.'
                });
            });
    }

    getBrokers(): void {
        this.brokerService.getBrokers()
            .subscribe((result: Broker[]) => {
                this.brokerList = result.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Brokers Not Loaded',
                    detail: 'Please be advised, unable to load Brokers.  Nothing to bind Broker drop down list with.'
                });
                this.amountTypes = null;
            });
    }

    getUser(): void {
        this.modifiedBy = this.authService.getPulteUsername();
    }

    goToPage(page: number = 1): void {
        setTimeout(() => {
            if (this.tableBanks) {
                this.tableBanks.first = (page - 1) * 10;
                this.tableBanks.firstChange.emit(this.tableBanks.first);
            }
        }, 0);
    }

    isExceptionAccess(): boolean {
        return this.authService.isBlcExceptionsUser();
    }

    isFullAccess(): boolean {
        return this.authService.isBlcFullAccessUser();
    }

    isReadOnlyAccess(): boolean {
        return this.authService.isBlcReadOnlyUser();
    }

    modalOpenAddBankClick(): void {
        this.displayAddDialog = true;
        this.isAddClicked = true;
    }

    onBankSearchEmit(evt: any): void {
        this.keyword = (evt?.data) ? evt?.data : null;
        this.onBankSearchSubmit(true);
    }

    onBankSearchKeyEnter(evt: any): void {
        this.keyword = evt?.target.value;
        this.filterBanks();
    }

    filterBanks() {
        if (this.keyword == null || this.keyword.length === 0) {
            // reset the search results
            this.banksFilterList = this.banksFullList;
            this.goToPage();
        } else {
            this.banksFilterList = this.banksFullList.filter((bank) => {
                return bank.name.toLowerCase().includes(this.keyword.toLowerCase());
            });
            if (this.banksFilterList.length === 0) {
                this.showNoResultsMessage = true;
            }
            else {
                this.showNoResultsMessage = false;
            }
            this.goToPage();
        }
    }

    onBankSearchSubmit(isEditWindowClose: boolean): void {
        this.showSearchResults = true;
        if (!isEditWindowClose) {
            this.goToPage();
        }
        // always get the full list of banks
        this.bankService.getBanksFromSearch('')
            .subscribe((banksFilteredList: BanksFilter[]) => {
                banksFilteredList.forEach((result) => {
                    this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
                });
                this.banksFullList = banksFilteredList;
                this.banksFilterList = banksFilteredList;

                if (this.banksFilterList.length === 0) {
                    this.showNoResultsMessage = true;
                }
                this.filterBanks();
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error loading results from search',
                    detail: `Error loading bank/surety results using keyword(s): "${this.keyword}"`
                });

                this.banksFilterList = null;
                this.showNoResultsMessage = true;
            });
    }

    onDialogClose(result) {
        this.displayAddDialog = false;
        this.isAddClicked = false;
        if (result) {
            this.onBankSearchSubmit(false);
        }
    }

    onEditButtonClick(bank: BankWithAmountTypes) {
        this.bankService.getBankWithCommitment(bank.id).pipe(
            switchMap((bankRes) => {
                this.selectedBank = bankRes;
                return this.bankAmountTypeService.getBankAmountTypeByBank(this.selectedBank.id);
            })
        ).subscribe((result: BankAmountType[]) => {
            this.selectedBank.amountTypes = [];

            result.forEach(res => {
                this.selectedBankAmountTypes.push(res.amountTypeId.toString());
                this.selectedBank.amountTypes.push(res.amountTypeId);
            });
            this.displayEditDialog = true;
        }, () => {
            this.messageService.add({
                severity: 'error',
                summary: 'Bank Amount Types Not Loaded',
                detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
            });

            this.selectedBankAmountTypes = null;
            this.displayEditDialog = true;
        });
    }

    onEditDialogClose(result) {
        this.displayEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
        if (result) {
            this.onBankSearchSubmit(true);
        }
    }

    sendChildMessage(messageArray: string[]): void {
        this.messageService.add({ severity: messageArray[0], summary: messageArray[1], detail: messageArray[2] });
    }
}
