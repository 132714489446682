// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .p-dialog .p-dialog-header {
    background: #336699;
}

::ng-deep .p-dialog .p-dialog-content {
    background: #336699;
}

::ng-deep .p-dialog .p-dialog-header .p-dialog-title {
    color: #fff;
    font-size: 16px;
}

:host ::ng-deep .p-dialog-header-close-icon {
    color: white;
    font-size: 2rem;
}

.required:after {
    content: " *";
    color: red;
}

.err-highlight {
    color: red;
}

.req-field {
    color: red;
}

::ng-deep .p-calendar {
    width: 100% !important;
}

::ng-deep .number-field-set-1 {
    width: 100%;
}

::ng-deep .number-field-set-2 {
    width: 245px;
}

#bond-lc-types-offered-container,
#is-containers {
    padding: 5px;
}

.well {
    border: solid 3px #e9c462;
}

hr {
    border-width: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/app/create-bank/create-bank.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["::ng-deep .p-dialog .p-dialog-header {\r\n    background: #336699;\r\n}\r\n\r\n::ng-deep .p-dialog .p-dialog-content {\r\n    background: #336699;\r\n}\r\n\r\n::ng-deep .p-dialog .p-dialog-header .p-dialog-title {\r\n    color: #fff;\r\n    font-size: 16px;\r\n}\r\n\r\n:host ::ng-deep .p-dialog-header-close-icon {\r\n    color: white;\r\n    font-size: 2rem;\r\n}\r\n\r\n.required:after {\r\n    content: \" *\";\r\n    color: red;\r\n}\r\n\r\n.err-highlight {\r\n    color: red;\r\n}\r\n\r\n.req-field {\r\n    color: red;\r\n}\r\n\r\n::ng-deep .p-calendar {\r\n    width: 100% !important;\r\n}\r\n\r\n::ng-deep .number-field-set-1 {\r\n    width: 100%;\r\n}\r\n\r\n::ng-deep .number-field-set-2 {\r\n    width: 245px;\r\n}\r\n\r\n#bond-lc-types-offered-container,\r\n#is-containers {\r\n    padding: 5px;\r\n}\r\n\r\n.well {\r\n    border: solid 3px #e9c462;\r\n}\r\n\r\nhr {\r\n    border-width: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
