import { CombinedDetailStatusEnum } from "./enums";

export class BankAndSuretyListingParam {
    constructor() {
        this.fromDateIndemnityAgreement = null;
        this.toDateIndemnityAgreement = null;
        this.isSurety = null;
        this.isActive = null;
        this.isRevolver = null;
        this.brokerId = null;
    }
    fromDateIndemnityAgreement: string | Date;
    toDateIndemnityAgreement: string | Date;
    isSurety: boolean;
    isActive: boolean;
    isRevolver: boolean;
    brokerId: number;
}

export class BlcRecapParam {
    fromDate: string;
    thruDate: string;

    constructor(fromDate, thruDate) {
        this.fromDate = fromDate;
        this.thruDate = thruDate;
    }
}

export class CombinedDetailParam {
    companyNumbers: string[] = [];
    type: number = -1;
    status: string | CombinedDetailStatusEnum = 'All';
    asOfDate: Date | string = new Date;
    fromAmount: number;
    toAmount: number;
}

export class CombinedSummaryParam {
    type: number = -1;
    broker: number = -1;
    isRevolver: number = -1;
    asOfDate: Date | string = new Date;

}

export class CostToCompleteByBankReportParam {
    bankIds: number[];
    fromAmount: number;
    toAmount: number;

    constructor() {
        this.bankIds = [];
        this.fromAmount = null;
        this.toAmount = null;
    }
}

export class CostToCompleteByCompanyReportParam {
    companyNumbers: string[];
    fromAmount: number;
    toAmount: number;

    constructor() {
        this.companyNumbers = [];
        this.fromAmount = null;
        this.toAmount = null;
    }
}

export class InvoiceBankSuretyParam {
    bankId: number;
    fromDateAp: string | Date;
    toDateAp: string | Date;

    constructor() {
        const todaysDate = new Date();
        const firstDayCurrentMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
        this.fromDateAp = firstDayCurrentMonth;
        this.toDateAp = todaysDate;
        this.bankId = null;
    }
}

export class InvoiceByCompanyReportParam {
    fromDateAp: string | Date;
    toDateAp: string | Date;
    companyNumbers: string[];

    constructor() {
        const todaysDate = new Date();
        const firstDayCurrentMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
        this.fromDateAp = firstDayCurrentMonth;
        this.toDateAp = todaysDate;
        this.companyNumbers = [];
    }
}

export class BlcAmendmentReportParam {
    isLetterOfCredit: boolean;
    fromEffDate: string | Date;
    toEffDate: string | Date;
    selectedBanks: number[];

    constructor() {
        const todaysDate = new Date();
        const firstDayCurrentMonth = new Date(todaysDate.getFullYear(), 0, 1);
        this.fromEffDate = firstDayCurrentMonth;
        this.toEffDate = todaysDate;
        this.selectedBanks = [];
    }
}
