import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lib-unauthenticated',
    templateUrl: './unauthenticated.component.html',
    styleUrls: ['./unauthenticated.component.css']
})
export class UnauthenticatedComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
