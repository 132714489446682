// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .p-datatable .p-datatable-header {
    color: black;
    background: #f5f5f5;
    padding: 0;
    border: none;
}

::ng-deep .p-datatable-thead > tr > th {
    background-color: silver !important;
    font-size: 12px;
}

.data-cache-header {
    text-align: right;
    margin-bottom: 5px;
}

.no-overflow {
    overflow: hidden;
}

.well {
    border: solid 1px #d3d3d3;
    margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/data-cache-manager/data-cache-manager.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,mCAAmC;IACnC,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":["::ng-deep .p-datatable .p-datatable-header {\r\n    color: black;\r\n    background: #f5f5f5;\r\n    padding: 0;\r\n    border: none;\r\n}\r\n\r\n::ng-deep .p-datatable-thead > tr > th {\r\n    background-color: silver !important;\r\n    font-size: 12px;\r\n}\r\n\r\n.data-cache-header {\r\n    text-align: right;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.no-overflow {\r\n    overflow: hidden;\r\n}\r\n\r\n.well {\r\n    border: solid 1px #d3d3d3;\r\n    margin: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
