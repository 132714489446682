import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Amendment, Invoice } from '../../../src/models';
import { environment } from '../../../src/environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class AmendmentService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    addAmendment(amendment: Amendment): Observable<Amendment> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(amendment);
        return this.http.post<Amendment>(environment.baseUrl + `amendments/createAmendment?`, body, opts) as Observable<Amendment>;
    }

    getAmendment(amendmentId: number): Observable<Amendment> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Amendment>(environment.baseUrl + `amendments/getamendment/${amendmentId}`, opts);
    }

    getAmendments(blcId: number): Observable<Amendment[]> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Amendment[]>(environment.baseUrl + `amendments/${blcId}`, opts);
    }

    removeAmendment(amendmentId: number): Observable<boolean> {
        const opts = this.sharedService.getOptsJson();
        return this.http.delete<boolean>(environment.baseUrl + `amendments/${amendmentId}`, opts);
    }

    updateAmendment(amendmentupdate: Amendment): Observable<Invoice> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(amendmentupdate);
        return this.http.put<Invoice>(environment.baseUrl + `amendments/updateAmendment?`, body, opts);
    }
}
