import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'negativeParentheses'
})
export class NegativeParenthesesPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const strValue: string = value.toString();
            return strValue.charAt(0) === '-' ?
                '(' + strValue.substring(1, strValue.length) + ')' :
                strValue;
        } else { return; }
    }
}
