// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
    margin: 5px;
}

.well {
    border: solid 1px #d3d3d3;
    margin: 5px;
}
s
label {
    font-size: 16px;
}

.modal-button {
    margin-left: 15px !important;
}

.no-overflow {
    overflow: hidden;
}

.results-table {
    border: none;
    margin: 5px;
}

::ng-deep .p-datatable .p-datatable-header {
    color: black;
    background: #f5f5f5;
    padding: 0;
}

::ng-deep .p-datatable-thead > tr > th {
    background-color: silver !important;
    font-size: 12px;
}

::ng-deep .p-datatable-tbody {
    font-size: 12px;
    overflow-wrap: break-word;
}

::ng-deep .p-datatable .p-datatable-header {
    border: none;
}

::ng-deep .p-datatable .p-datatable-tbody > tr > td {
    padding: .5rem;
}


`, "",{"version":3,"sources":["webpack://./src/app/search-bank/search-bank.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;AACA;;IAEI,eAAe;AACnB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,mCAAmC;IACnC,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["h2 {\r\n    margin: 5px;\r\n}\r\n\r\n.well {\r\n    border: solid 1px #d3d3d3;\r\n    margin: 5px;\r\n}\r\ns\r\nlabel {\r\n    font-size: 16px;\r\n}\r\n\r\n.modal-button {\r\n    margin-left: 15px !important;\r\n}\r\n\r\n.no-overflow {\r\n    overflow: hidden;\r\n}\r\n\r\n.results-table {\r\n    border: none;\r\n    margin: 5px;\r\n}\r\n\r\n::ng-deep .p-datatable .p-datatable-header {\r\n    color: black;\r\n    background: #f5f5f5;\r\n    padding: 0;\r\n}\r\n\r\n::ng-deep .p-datatable-thead > tr > th {\r\n    background-color: silver !important;\r\n    font-size: 12px;\r\n}\r\n\r\n::ng-deep .p-datatable-tbody {\r\n    font-size: 12px;\r\n    overflow-wrap: break-word;\r\n}\r\n\r\n::ng-deep .p-datatable .p-datatable-header {\r\n    border: none;\r\n}\r\n\r\n::ng-deep .p-datatable .p-datatable-tbody > tr > td {\r\n    padding: .5rem;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
