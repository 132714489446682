export enum CombinedDetailStatusEnum {
    //All = 'All',
    Active = 'Active',
    Canceled = 'Canceled',
    Expired = 'Expired',
    Pending = 'Pending'
}

export const CombinedDetailStatusToLabelMapping: Record<CombinedDetailStatusEnum, string> = {
    //[CombinedDetailStatusEnum.All]: '-- Select All --',
    [CombinedDetailStatusEnum.Active]: 'Active',
    [CombinedDetailStatusEnum.Canceled]: 'Canceled',
    [CombinedDetailStatusEnum.Expired]: 'Expired',
    [CombinedDetailStatusEnum.Pending]: 'Pending'
}
