import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

import { InvoiceUploadParam } from '../../../../src/models';
import { BlcDatePipe } from '../../../../src/pipes';

import { AuthorizationService } from '../../../services';

@Component({
    selector: 'app-invoice-upload',
    templateUrl: './invoice-upload.component.html',
    styleUrls: ['./invoice-upload.component.css']
})

export class InvoiceUploadComponent implements OnInit {

    @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;

    fileLimit: number = 1;
    gracePeriodMaxAmount: number = 5;
    isValidParams: boolean = false;
    marginMaxAmount: number = 100;
    maxUploadSize: number = 52428800;  //  file size 50 megabytes - 52428800 bytes
    minAmount: number = 0;
    showModal: boolean = false;
    uploadParam: InvoiceUploadParam = new InvoiceUploadParam();
    verificationMaxAmount: number = 1000;
    username: string = this.authService.getPulteUsername();

    constructor(private authService: AuthorizationService,
        private blcDatePipe: BlcDatePipe,
        private currencyPipe: CurrencyPipe,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.authService.checkBlcUserAccess();
        this.uploadParam = new InvoiceUploadParam();
        this.uploadParam.modifiedBy = this.username;
    }

    closeModal(): void {
        this.showModal = false;
    }

    private csvJSON(csv) {
        const lines: string[] = csv.split("\r\n");
        const result = [];
        const headersSplit = lines[2].split(","); // third line is header row in file
        const headersClean: string[] = [];
        headersSplit.forEach(hdr => {
            const cleanHeader = hdr.replace(' ', '');
            headersClean.push(cleanHeader);
        });
        this.uploadParam.fileBrokerName = lines[0].substring(lines[0].indexOf('<<<') + 3, lines[0].lastIndexOf('>>>'));
        this.uploadParam.fileDate = lines[1].split(',')[0];

        for (var i = 3; i < lines.length; i++) {
            const obj = {};
            const currentline = lines[i].split(",");
            for (var j = 0; j < headersClean.length; j++) {
                obj[headersClean[j]] = currentline[j];
            }
            result.push(obj);
        }
        return result; //JSON
    }

    getSizeInMegaBytes(file: File) {
        return file ? file.size / 1000000 : 0;
    }

    onAPDateChange(event): void {
        if (event.target?.value === 'mm/dd/yyyy') {
            this.uploadParam.apDate == null;
        }
        this.validateParams(this.uploadParam);
    }

    onContinueClick(): void {
        this.uploadParam.apDate = this.blcDatePipe.transform(this.uploadParam.apDate);
        this.uploadParam.fileName = this.uploadParam.file.name;
        this.uploadParam.modifiedBy = (this.username.length == 0) ? 'BLC System' : this.username;
        // Convert file to JSON
        const reader = new FileReader();
        reader.onload = () => {
            let text = reader.result;

            //convert text to json here
            var json = this.csvJSON(text);
            this.uploadParam.fileJson = json;
            this.showModal = true;
        };
        reader.readAsText(this.uploadParam.file);
        // Check file layout

    }

    onFileSelect(fileUpload: any) {
        let resetUpload = false;
        let fileCount = 0;
        let fileSizeTotal = 0;

        fileUpload?.files.forEach((selected: File) => {
            fileSizeTotal = fileSizeTotal + selected.size;
            if (fileSizeTotal > this.maxUploadSize) {
                resetUpload = true;
                this.messageService.add({
                    severity: 'info',
                    summary: 'Size too big information',
                    detail: `File thus far totals: ${fileSizeTotal} and is too big.  Max file size is: ${this.maxUploadSize} bytes.`
                });
            }
        });

        for (const { } of fileUpload?.files) {
            fileCount++;
            if (fileCount > this.fileLimit) {
                resetUpload = true;
                this.messageService.add({
                    severity: 'info',
                    summary: 'Too many files per upload',
                    detail: `Too many files selected in this upload.  File limit is: ${this.fileLimit}.`
                });
            }
        }

        if (resetUpload) {
            fileUpload?.clear();
        }
        this.validateParams(this.uploadParam);
    }

    onInputChange(eventValue: any, inputName: string): void {
        if (inputName == 'margin' && (eventValue < this.minAmount || eventValue > this.marginMaxAmount)) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: 'Invoice Margin of Error % is out of range. Please set Margin of Error to more than ' + this.minAmount + 'and  less than ' + this.marginMaxAmount + '.'
            });
            this.uploadParam.marginOfError = eventValue;
        } else if (inputName == 'margin' && (eventValue >= this.minAmount || eventValue <= this.marginMaxAmount)) {
            this.uploadParam.marginOfError = eventValue;
        }

        if (inputName == 'amount' && (eventValue < this.minAmount || eventValue > this.verificationMaxAmount)) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: 'Minimum Invoice Verification Amount is out of range. Please set amount to more than ' +
                    this.currencyPipe.transform(this.minAmount, 'USD', 'symbol', '1.2-2') + ' and less than ' +
                    this.currencyPipe.transform(this.verificationMaxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.uploadParam.verificationAmount = eventValue;
        } else if (inputName == 'amount' && (eventValue >= this.minAmount || eventValue <= this.verificationMaxAmount)) {
            this.uploadParam.verificationAmount = eventValue;
        }

        if (inputName == 'period' && (eventValue < this.minAmount || eventValue > this.gracePeriodMaxAmount)) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: 'Overlapping Date Grace Period is out of range. Please set Grace Period to more than ' + this.minAmount + ' and less than ' + this.gracePeriodMaxAmount + '.'
            });
            this.uploadParam.gracePeriod = eventValue;
        } else if (inputName == 'period' && (eventValue >= this.minAmount || eventValue <= this.gracePeriodMaxAmount)) {
            this.uploadParam.gracePeriod = eventValue;
        }

        this.validateParams(this.uploadParam);
    }

    onRemoveFile(): void {
        this.fileUpload.clear();
        this.uploadParam.file = null;
        this.validateParams(this.uploadParam);
    }

    onResetSearch() {
        this.resetParams();
    }

    resetParams(): void {
        this.uploadParam = new InvoiceUploadParam();
        this.uploadParam.modifiedBy = this.username;
        this.fileUpload.clear();
        this.validateParams(this.uploadParam);
    }

    uploader(event): void {
        this.uploadParam.file = event.files[0];
        this.validateParams(this.uploadParam);
    }

    validateParams(param: InvoiceUploadParam): void {
        if ((!param.apDate || param.apDate === '') ||
            (param.marginOfError == null || isNaN(param.marginOfError) || param.marginOfError < this.minAmount || param.marginOfError > this.marginMaxAmount) ||
            (param.verificationAmount == null || isNaN(param.verificationAmount) || param.verificationAmount < this.minAmount || param.verificationAmount > this.verificationMaxAmount) ||
            (param.gracePeriod == null || isNaN(param.gracePeriod) || param.gracePeriod < this.minAmount || param.gracePeriod > this.gracePeriodMaxAmount) ||
            (!param.file || param.file.type != 'text/csv')) {
            this.isValidParams = false;
        } else {
            this.isValidParams = true;
        }
    }
}
