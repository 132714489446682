export class RateChangeSummary {
    name: string;
    effectiveDate: string | Date;
    oldStandardRate: number;
    newStandardRate: number;
    oldFinancialGuaranteeRate: number;
    newFinancialGuaranteeRate: number;
    dateModified: string | Date;
    standardCount: number;
    financialGuaranteeCount: number;
    type: string;
}