// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imposeMarginL {
    margin-left: 24px !important;
}

.imposeMarginR {
    margin-right: 24px !important;
}

.imposeFloat {
    float: right !important;
}

.dateLabel {
    display: block;
}

.well {
    border: solid 1px #d3d3d3;
    margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/reports/b-lc-amendment/b-lc-amendment.component.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".imposeMarginL {\r\n    margin-left: 24px !important;\r\n}\r\n\r\n.imposeMarginR {\r\n    margin-right: 24px !important;\r\n}\r\n\r\n.imposeFloat {\r\n    float: right !important;\r\n}\r\n\r\n.dateLabel {\r\n    display: block;\r\n}\r\n\r\n.well {\r\n    border: solid 1px #d3d3d3;\r\n    margin: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
