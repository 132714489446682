import { Injectable } from '@angular/core';

import { BLCCryptography } from './blccryptography';
import { ISecureStorage } from './interfaces/isecurestorage';
import { IStorage } from './interfaces/istorage';
import { StorageItem } from './storageitem';

@Injectable({
    providedIn: "root"
})
export class SecureStorage implements ISecureStorage {

    constructor(private storage: IStorage, private crypto: BLCCryptography) {

    }

    clear(): void {
        this.storage.clear();
    }

    getItem<T>(key: string): T | T[] {
        const value = this.storage.getItem(this.crypto.shaHash(key));
        if (!value) return;

        const result: any = this.crypto.decrypt(value);
        const storageResult: StorageItem = JSON.parse(result)

        var isExpired = this.checkExpiration(key, storageResult)

        if (isExpired) return;

        if (Array.isArray(storageResult.value)) {
            const arrayResult = Array.from<T>(storageResult.value)
            return arrayResult;
        }

        const objectResult = <T>(Object.assign({}, storageResult.value));
        return objectResult;
    }

    setItem(key: string, data: any, customExpirationDate?: Date): void {
        var storedData = this.setExpiration(data, customExpirationDate);
        var jsonData = JSON.stringify(storedData);
        this.storage.setItem(this.crypto.shaHash(key), this.crypto.encrypt(jsonData));
    }

    setMD5Item(key: string, data: any): void {
        var md5Data = this.crypto.md5Hash(data);
        var jsonData = JSON.stringify(md5Data);
        this.storage.setItem(key, jsonData);
    }

    removeItem(key: string): void {
        this.storage.removeItem(this.crypto.shaHash(key));
    }

    private setExpiration(data: any, customExpirationDate?: Date): StorageItem {
        const currentDate = new Date();
        const defaultExpiration = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
        const expirationDate = customExpirationDate ?? defaultExpiration;

        const storedData: StorageItem = {
            expiration: expirationDate,
            value: data
        };

        return storedData;
    }

    private checkExpiration(key: string, data: StorageItem): boolean {
        const currentDateTime = new Date();
        const isExpired = currentDateTime > new Date(data.expiration) || !data?.expiration;

        if (isExpired) {
            this.removeItem(key);
        }

        return isExpired;
    }
}
