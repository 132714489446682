export class InvoiceUploadResult {
    amount: string;
    amountNum: number = 0;
    bankId: number;
    bankName: string;
    bondId: number;
    bondNumber: string;
    calculatedInvoiceAmount: number = 0;
    dateCanceled: Date;
    datePayPeriodEnd: Date;
    datePayPeriodStart: Date;
    errorFound: boolean;
    validationResult: string;
    groupKey: number = 0;
    bondIdError: boolean = false;
    fileId: number;
}
