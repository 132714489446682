// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p-progressSpinner {
    background-color: #00000048;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: grid;
    stroke: #033663 !important;
    z-index: 100000;
}

.loading-spinner {
    overflow-y: hidden;
    height: 100vh;
}

.loading-spinner img {
    align-self: end;
}

.loading-message {
    text-align: center;
    align-self: start;
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {

    100%,
    0% {
        stroke: #336699;
    }

    40% {
        stroke: #336699;
    }

    66% {
        stroke: #336699;
    }

    80%,
    90% {
        stroke: #336699;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/loading-spinner/loading-spinner.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,wHAAwH;AAC5H;;AAEA;;IAEI;;QAEI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;;QAEI,eAAe;IACnB;AACJ","sourcesContent":["p-progressSpinner {\r\n    background-color: #00000048;\r\n    position: absolute;\r\n    width: 100%;\r\n    top: 0px;\r\n    left: 0px;\r\n    height: 100vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    display: grid;\r\n    stroke: #033663 !important;\r\n    z-index: 100000;\r\n}\r\n\r\n.loading-spinner {\r\n    overflow-y: hidden;\r\n    height: 100vh;\r\n}\r\n\r\n.loading-spinner img {\r\n    align-self: end;\r\n}\r\n\r\n.loading-message {\r\n    text-align: center;\r\n    align-self: start;\r\n}\r\n\r\n:host ::ng-deep .custom-spinner .p-progress-spinner-circle {\r\n    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;\r\n}\r\n\r\n@keyframes custom-progress-spinner-color {\r\n\r\n    100%,\r\n    0% {\r\n        stroke: #336699;\r\n    }\r\n\r\n    40% {\r\n        stroke: #336699;\r\n    }\r\n\r\n    66% {\r\n        stroke: #336699;\r\n    }\r\n\r\n    80%,\r\n    90% {\r\n        stroke: #336699;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
