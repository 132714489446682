import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { NotificationsService } from '../../services';

@Component({
    selector: 'app-notifications',
    template: `<p-toast position="top-right" life="3000"></p-toast>
               <p-messages [value]="message"></p-messages>`,
    providers: [MessageService]
})
export class NotificationsComponent implements OnInit, OnDestroy {
    toast: Message[] = [];
    message: Message[] = [];
    toastSubscription: Subscription;
    messageSubscription: Subscription;

    constructor(private notificationsService: NotificationsService) { }

    ngOnInit() {
        this.subscribeToToastNotifications();
        this.subscribeToMessageNotifications();
    }

    ngOnDestroy() {
        this.toastSubscription.unsubscribe();
        this.messageSubscription.unsubscribe();
    }

    subscribeToMessageNotifications() {
        this.messageSubscription = this.notificationsService.messageNotificationChange
            .subscribe((notification: Message[]) => {
                this.message.length = 0;
                notification.forEach(element => {
                    this.message.push(element);
                });
            });
    }

    subscribeToToastNotifications() {
        this.toastSubscription = this.notificationsService.toastNotificationChange
            .subscribe(notification => {
                this.toast.push(notification);
                setTimeout(() => {
                    this.toast.splice(this.toast.indexOf(notification), 1);
                }, 3000);
            });
    }
}
