import { Pipe, PipeTransform } from '@angular/core';
import { AmountSubType } from '../models';

@Pipe({ name: 'amountSubTypeName' })
export class AmountSubTypeNamePipe implements PipeTransform {
    transform(amountSubTypeId: number, amountSubTypeList: AmountSubType[]): string {
        if (amountSubTypeList?.length > 0) {
            const result = amountSubTypeList.find(element => element.id === amountSubTypeId);
            return result?.name;
        }

        return null;
    }
}
