import { Component, OnDestroy, OnInit } from '@angular/core';

import { LoadingIndicatorService } from '../../services';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
    sub: any;
    loading: boolean;

    constructor(private loadingIndicatorService: LoadingIndicatorService) {
        this.sub = this.loadingIndicatorService.isLoading.subscribe((v) => {
            this.loading = v;
        });
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
