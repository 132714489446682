import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AmountType } from '../../models';
import { environment } from '../../../src/environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class AmountTypeService {

    private cachedAmountTypes: Observable<AmountType[]> = null;

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getAmountTypes(): Observable<AmountType[]> {
        if (!this.cachedAmountTypes) {
            const opts = this.sharedService.getOptsApplication();
            this.cachedAmountTypes = this.http.get<AmountType[]>(environment.baseUrl + `amountTypes`, opts);
        }

        return this.cachedAmountTypes;
    }

    dropCache(): void {
        this.cachedAmountTypes = null;
    }
}
