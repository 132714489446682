// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

h4 {
    text-align: center;
}

.side-header,
h4 {
    font-size: 24px;
}

.sidenav a {
    padding: 12px 15px 12px 15px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sidenav a .expandCarrot {
    font-size: 13px;
    color: gray;
}

.sidenav a:hover {
    background-color: #336699;
}

.sub-item {
    font-size: 13px !important;
}

.menu-icon {
    padding: 0px 10px !important;
    color: white !important;
    width: 40px !important;
}

.pi-angle-right {
    width: 0px !important;
}

::ng-deep .p-component {
    font-size: 14.5px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.selected {
    color: #336699;
    background-color: white;
    font-weight: bold;
}

#sidebar {
    min-width: 100%;
    max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/navigation/navigation.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,cAAc;IACd,2DAA2D;AAC/D;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;IAC5B,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,2DAA2D;AAC/D;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":[".img-centered {\r\n    display: block;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    width: 100%;\r\n}\r\n\r\nh4 {\r\n    text-align: center;\r\n}\r\n\r\n.side-header,\r\nh4 {\r\n    font-size: 24px;\r\n}\r\n\r\n.sidenav a {\r\n    padding: 12px 15px 12px 15px;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    color: white;\r\n    display: block;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n}\r\n\r\n.sidenav a .expandCarrot {\r\n    font-size: 13px;\r\n    color: gray;\r\n}\r\n\r\n.sidenav a:hover {\r\n    background-color: #336699;\r\n}\r\n\r\n.sub-item {\r\n    font-size: 13px !important;\r\n}\r\n\r\n.menu-icon {\r\n    padding: 0px 10px !important;\r\n    color: white !important;\r\n    width: 40px !important;\r\n}\r\n\r\n.pi-angle-right {\r\n    width: 0px !important;\r\n}\r\n\r\n::ng-deep .p-component {\r\n    font-size: 14.5px;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n}\r\n\r\n.selected {\r\n    color: #336699;\r\n    background-color: white;\r\n    font-weight: bold;\r\n}\r\n\r\n#sidebar {\r\n    min-width: 100%;\r\n    max-width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
