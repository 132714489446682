export class Document {
    id: string;
    documentName: string;
    description: string;
    documentPath: string;
    dateCreated: Date;
    createdBy: string;
    dateModified: Date;
    modifiedBy: string;
    blcId: number;
    contentType: string;
}
