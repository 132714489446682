import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: Date | string): string | null {
    if (!value) {
      return null;
    }
    const date = (typeof value === 'string') ? new Date(value) : value;
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const dateFinal = `${month}/${day}/${year}`;
    return dateFinal;
  }
}
