import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as fs from 'file-saver';


@Injectable()
export class ExcelService {

    constructor(private datePipe: DatePipe) { }

    static toExportFileName(excelFileName: string, date: string): string {
        return `${excelFileName}_export_${date}.xlsx`;
    }

    public exportAsExcelFile(workbook: any, excelFileName: string): void {

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const date = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
            fs.saveAs(blob, ExcelService.toExportFileName(excelFileName, date));
        });
    }
}
