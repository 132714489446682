import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';

import { AuthorizationService } from '../../services';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements AfterViewChecked {
    isFullAccess: boolean = false;
    isLimitedAccess: boolean = false;
    isReadOnlyAccess: boolean = false;

    constructor(private authService: AuthorizationService, private cdr: ChangeDetectorRef) { }

    ngAfterViewChecked() {
        this.setNavigationRoles();
        this.cdr.detectChanges();
    }

    setNavigationRoles() {
        this.isFullAccess = this.authService.isBlcFullAccessUser();
        this.isLimitedAccess = this.authService.isBlcExceptionsUser();
        this.isReadOnlyAccess = this.authService.isBlcReadOnlyUser();
    }
}
