export class BankAndSuretyListing {
    constructor() {
        this.id = 0;
        this.name = '';
        this.isSurety = false;
        this.brokerName = '';
        this.dateIndemnityAgreement = new Date();
        this.isActive = false;
        this.isPowerOfAttorney = false;
        this.isRevolver = false;
        this.amountTypes = '';
        this.commitAmount = null;
        this.rate = null;
        this.financialGuaranteeCommitAmount = null;
        this.financialGuaranteeRate = null;
    }
    id: number;
    name: string;
    isSurety: boolean;
    brokerName: string;
    dateIndemnityAgreement: Date;
    isActive: boolean;
    isPowerOfAttorney: boolean;
    isRevolver: boolean;
    amountTypes: string;
    commitAmount: number;
    rate: number;
    financialGuaranteeCommitAmount: number;
    financialGuaranteeRate: number;
}

export class CombinedDetailSprocResult {
    bondId: number;
    company: string;
    community: string;
    bankId: number;
    bank: string;
    isLetterOfCredit: boolean;
    status: string;
    bondNumber: string;
    dateIssued: Date;
    dateExpired: Date;
    beneficiary: string;
    amountType: string;
    amountSubType: string;
    amountDescription: string;
    amount: string;
    rate?: number;
    isAutoRenew: boolean | string;
}

export class CombinedDetailExportResult {
    company: string;
    community: string;
    bank: string;
    status: string;
    bondNumber: string;
    dateIssued: string | Date;
    dateExpired: string | Date;
    beneficiary: string;
    amountTypeAndDescription: string;
    amount: string;
    rate: string;
    isAutoRenew: string;
}

export class CombinedSummaryResult {
    isSurety: boolean;
    name: string;
    bondCount: number;
    commitAmount: number;
    availability: string | number;
    outstandingAmount: number;
    availabilityChangeFromPriorMonth: number;
    rate: number;
    financialGuaranteeRate: number;
    averageRate: number;
    totalCost: number;
    bankId: number;
    groupKey: number;

    constructor() {
        this.bankId = null;
        this.isSurety = null;
        this.name = '';
        this.bondCount = 0;
        this.commitAmount = 0;
        this.availability = 0;
        this.outstandingAmount = 0;
        this.availabilityChangeFromPriorMonth = 0;
        this.rate = 0;
        this.financialGuaranteeRate = 0;
        this.averageRate = 0;
        this.totalCost = 0;
        this.groupKey = 0;
    }
}

export class CombinedSummaryReport {
    isSurety: string;
    name: string;
    bondCount: string;
    commitAmount: string;
    availability: string;
    outstandingAmount: string;
    availabilityChangeFromPriorMonth: string;
    rate: string;
    financialGuaranteeRate: string;
    averageRate: string;
    totalCost: string;    
}

export class CostToCompleteByBankReport {
    bondId: number;
    bondNumber: string;
    company: string;
    community: string;
    bankId: number;
    bank: string;
    bondAmount: number;
    beneficiary: string;
    bondType: string;
    costToComplete: number;
    costToCompleteAmt: number;
    estimatedReleaseDate: Date;
    comments: string;
    ldaPhaseCode: string;
}

export class CostToCompleteByCompanyReport {
    bondId: number;
    bondNumber: string;
    company: string;
    community: string;
    bankId: number;
    bank: string;
    bondAmount: number;
    beneficiary: string;
    bondType: string;
    costToComplete: number;
    costToCompleteAmt: number;
    estimatedReleaseDate: Date;
    comments: string;
    ldaPhaseCode: string;
    marketNumber: string;
    communityNumber: string;
    marketNumberSortKey: number;
    communityNumberSortKey: number;
}

export class InvoiceBankSurety {
    bankId: number;
    bank: string;
    bondId: number;
    bondNumber: string;
    dateAP: Date;
    amount: number;
    datePayPeriodStart: Date;
    datePayPeriodEnd: Date;
    company: string;
    community: string;
    bondAmount: number;
    rate: number;
    description: string;
    ldaPhaseCode: string;
}

export class InvoiceBankSuretyReport {
    name: string;
    company: string;
    community: string;
    lda: string;
    bondNumber: string;
    currentAmount: number;
    rate: number;
    fee: number;
    apDate: string | Date;
    invoiceStartDate: string | Date;
    invoiceEndDate: string | Date;
    notes: string;
    bankId: number;
    bondId: number;
    groupKey: number;
}

export class InvoiceBankSuretyExcel {
    name: string;
    company: string;
    community: string;
    lda: string;
    bondNumber: string;
    currentAmount: string;
    rate: string;
    fee: string;
    apDate: Date;
    invoiceStartDate: Date;
    invoiceEndDate: Date;
    notes: string;
}

export class InvoiceByCompanyReport {
    company: string;
    community: string;
    bankId: number;
    bank: string;
    bondAmount: number;
    rate: number
    bondId: number;
    bondNumber: string;
    dateAP: Date;
    startDate: Date;
    endDate: Date;
    description: string;
    amount: number;
    groupKey: number;
}

export class InvoiceByCompanyExcel {
    company: string;
    community: string;    
    bank: string;
    bondAmount: string;
    rate: number    
    bondNumber: string;
    dateAP: Date;
    startDate: Date;
    endDate: Date;
    description: string;
    amount: string;
}

export class ReportColumn {
    field: string;
    header: string;
    widthPercentage?: number;
    link?: string;
    dataType?: string;
    lineBreakCharacter?: string; 
}

export class BlcAmendmentReport {
    bankId: number;
    bankName: string;
    companyName: string;
    bondId: number;
    bondNumber: string;
    amendmentId: number;
    effectiveDate: Date;
    bondAmount: number;
    currentBondAmount: number;
    amendmentAmount: number;
    amendmentRequest: string;
    isLetterOfCredit: boolean;
    marketNumber: string;
}
