import { Pipe, PipeTransform } from '@angular/core';
import { Bank } from '../models';

@Pipe({ name: 'bankName' })
export class BankNamePipe implements PipeTransform {
    transform(bankId: number, bankList: Bank[]): string {
        if (bankList?.length > 0) {
            const result = bankList.find(element => element.id === bankId);
            return result?.name;
        }

        return null;
    }
}
