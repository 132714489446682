import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';

import { CommonFunctions } from '../../../../../src/functions';
import {
    AmountType, Bank, BankAmountType, BankWithAmountTypes, BankWithFinancialGuarantee, Bond,
    Company, Document, InvoiceUploadParam, InvoiceUploadResult
} from '../../../../../src/models';
import { AmountTypeEnum } from '../../../../../src/models/enums';
import { BlcDatePipe } from '../../../../../src/pipes';

import {
    AmountTypeService, BankAmountTypeService, BankService, BondService,
    CompanyService, DocumentService, ExcelService, InvoiceService
} from '../../../../services';

@Component({
    selector: 'app-invoice-upload-results',
    templateUrl: './invoice-upload-results.component.html',
    styleUrls: ['./invoice-upload-results.component.css']
})

export class InvoiceUploadResultsComponent implements OnInit {
    @Input() showModal: boolean = false;
    @Input() uploadParam: InvoiceUploadParam;

    @Output() closeModalEvent = new EventEmitter();
    @Output() uploadCompleteEvent = new EventEmitter();

    amountTypeList: AmountType[] = [];
    availableCommitment: number = null;
    bankData: BankWithFinancialGuarantee;
    banksForDialog: Bank[] = [];
    bondData: Bond;
    cols: any[] = [];
    companyList: Company[];
    displayBankEditDialog: boolean = false;
    displayEditDialog: boolean = false;
    documents: Document[];
    exportHeader: string[] = [];
    fileId: number;
    filteredCount: InvoiceUploadResult[] = [];
    isAmountTypeFinancialGuarantee: boolean;
    isColumnsSortable: boolean = false;
    isLoading: boolean = false;
    isStatusCanceled: boolean;
    isValidParams: boolean = true;
    selectedBank: BankWithAmountTypes;
    selectedBankAmountTypes: string[] = [];
    singleBondLimit: string = null;
    showError: boolean = false;
    showResults: boolean = false;
    results: InvoiceUploadResult[] = [];
    verifiedResultsCount: number = 0;

    constructor(private amountTypeService: AmountTypeService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private blcDatePipe: BlcDatePipe,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private companyService: CompanyService,
        private documentService: DocumentService,
        private excelService: ExcelService,
        private invoiceService: InvoiceService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.cols = [
            { field: 'bondNumber', link: 'bondId', header: 'FILE Instrument No.', widthPercentage: 16 },
            { field: 'bankName', link: 'bankId', header: 'Bank/Surety', widthPercentage: 16 },
            { field: 'dateCanceled', dataType: 'date', header: 'Cancel Date', widthPercentage: 9 },
            { field: 'amount', dataType: 'currency', header: 'FILE Invoice Amt', widthPercentage: 9 },
            { field: 'calculatedInvoiceAmount', dataType: 'currency', header: 'Calculated Invoice Amount', widthPercentage: 9 },
            { field: 'datePayPeriodStart', dataType: 'date', header: 'FILE Payable Start Date', widthPercentage: 9 },
            { field: 'datePayPeriodEnd', dataType: 'date', header: 'FILE Payable End Date', widthPercentage: 9 },
            { field: 'validationResult', header: 'ERROR Message', widthPercentage: 23 },
        ];

        this.mapFileResults();

        this.cols.forEach(col => {
            this.exportHeader.push(col.header);
        });

        this.showResults = false;

        this.invoiceService.checkInvoicesForUpload(this.uploadParam).subscribe(results => {
            this.filteredCount = results;
            this.results = [];
            this.verifiedResultsCount = 0;
            this.fileId = 0;

            // Format the date objects to remove timestamps
            results.forEach((result) => {
                this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
            });

            // Split the resultset
            const errorResults: InvoiceUploadResult[] = results.filter(res => res.errorFound == true);
            const verifiedResults: InvoiceUploadResult[] = results.filter(res => res.errorFound == false);

            // Handle and calculate total row for errors
            const errorTotalRow: InvoiceUploadResult = new InvoiceUploadResult();
            errorTotalRow.bondNumber = 'Invoice errors: ' + errorResults.length;
            errorResults.forEach(err => {
                if (err.validationResult.includes('FILE Instrument No')) {
                    err.bondIdError = true;
                }
                errorTotalRow.amountNum += Number.parseFloat(err.amount);
                errorTotalRow.calculatedInvoiceAmount += err.calculatedInvoiceAmount;
            });
            errorTotalRow.amount = errorTotalRow.amountNum.toString();
            errorTotalRow.groupKey = 11000;

            // Handle and calculate total row for verified records
            const verifiedTotalRow: InvoiceUploadResult = new InvoiceUploadResult();
            verifiedTotalRow.bondNumber = 'Invoices VERIFIED TOTAL: ' + verifiedResults.length;
            this.verifiedResultsCount = verifiedResults.length;
            verifiedResults.forEach(ver => {
                if (this.fileId == 0) {
                    this.fileId = ver.fileId;
                }
                verifiedTotalRow.amountNum += Number.parseFloat(ver.amount);
                verifiedTotalRow.calculatedInvoiceAmount += ver.calculatedInvoiceAmount;
            })
            verifiedTotalRow.amount = verifiedTotalRow.amountNum.toString();
            verifiedTotalRow.groupKey = 11000;

            const formattedVerifiedResults: InvoiceUploadResult[] = [];
            // Group verified records by bank and generate bank total rows
            const bankGroups = this.commonFunctions.groupBy(verifiedResults, 'bankName');
            if (Object.keys(bankGroups).length > 0) {
                // For each bank in verified resultset
                for (const bankName in bankGroups) {
                    const bankReport = bankGroups[bankName];
                    // Set bank-specific total variables
                    const bankTotalRow: InvoiceUploadResult = new InvoiceUploadResult();
                    bankTotalRow.bondNumber = 'Invoices verified: ' + bankReport.length;

                    // For each row in specific bank group
                    for (const index in bankReport) {
                        const dataRow = bankReport[index];
                        bankTotalRow.amountNum += Number.parseFloat(dataRow.amount);
                        bankTotalRow.calculatedInvoiceAmount += dataRow.calculatedInvoiceAmount;
                        formattedVerifiedResults.push(dataRow);
                    }
                    bankTotalRow.amount = bankTotalRow.amountNum.toString();
                    bankTotalRow.groupKey = 11000;
                    formattedVerifiedResults.push(bankTotalRow);
                }
            }

            // Handle and calculate grand total row
            const grandTotalRow: InvoiceUploadResult = new InvoiceUploadResult();
            grandTotalRow.bondNumber = 'Invoices GRAND TOTAL: ' + this.filteredCount.length;
            grandTotalRow.amount = (errorTotalRow.amountNum + verifiedTotalRow.amountNum).toString();
            grandTotalRow.calculatedInvoiceAmount = errorTotalRow.calculatedInvoiceAmount + verifiedTotalRow.calculatedInvoiceAmount;
            grandTotalRow.groupKey = 11000;

            // Consolidate down the data into results with total rows included
            if (errorResults.length > 0) {
                errorResults.forEach(rec => {
                    this.results.push(rec);
                });
                this.results.push(errorTotalRow);
            }
            if (verifiedResults.length > 0) {
                formattedVerifiedResults.forEach(rec => {
                    this.results.push(rec);
                });

                if (errorResults.length > 0) {
                    this.results.push(verifiedTotalRow);
                }
                this.results.push(grandTotalRow);
            }

            this.showResults = true;
        }, () => {
            this.messageService.add({
                severity: 'error',
                summary: 'Error Occurred during File Upload',
                detail: `An unexpected error has occurred during the file upload process.`
            });
            this.showError = true;
        });

        //  load amount types !
        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occurred',
                    detail: 'An error occurred getting Amount Types.'
                });
            });

        this.companyService.getCompanies()
            .subscribe((companies) => {
                this.companyList = companies;
                this.companyList = this.commonFunctions.sortAndFormatCompanies(this.companyList);
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occurred',
                    detail: 'An error occurred getting Companies.'
                });
            });
    }

    closeModal(): void {
        this.closeModalEvent.emit();
    }

    formatResultsForExport(): any[] {
        const formattedResults: any[] = [];
        const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

        this.results.forEach(res => {
            const format = {
                bondNumber: res.bondNumber,
                bankName: res.bankName,
                dateCanceled: res.dateCanceled,
                amount: Number.parseFloat(res.amount) < 0 ? '($' + Number.parseFloat(res.amount).toLocaleString('en', decimalFormat) + ')' : '$' + Number.parseFloat(res.amount).toLocaleString('en', decimalFormat),
                calculatedInvoiceAmount: res.calculatedInvoiceAmount < 0 ? '($' + res.calculatedInvoiceAmount.toLocaleString('en', decimalFormat) + ')' : '$' + res.calculatedInvoiceAmount.toLocaleString('en', decimalFormat),
                datePayPeriodStart: res.datePayPeriodStart,
                datePayPeriodEnd: res.datePayPeriodEnd,
                validationResult: res.validationResult
            };
            formattedResults.push(format);
        });

        return formattedResults;
    }

    mapFileResults() {
        const unformattedFile = this.uploadParam.fileJson;
        const formattedArray = [];

        unformattedFile.forEach(line => {
            const properties = Object.keys(line);
            const formatted = {
                bondNumber: line[properties[0]],
                rate: line[properties[1]],
                grossPremium: line[properties[2]],
                effectiveDate: line[properties[3]],
                expirationDate: line[properties[4]]
            };

            formattedArray.push(formatted);
        });

        this.uploadParam.fileJson = formattedArray;
    }

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onBondModalEditDialogClose(): void {
        this.displayEditDialog = false;
    }

    onExportClick(): void {
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('InvoiceUploadResults');

        // Add title row
        const titleRow = worksheet.addRow(['Invoice Upload Results']);
        worksheet.mergeCells('A1:H1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 20;
        });

        // Set up subtitle data area for each of 4 rows
        worksheet.addRow(['AP Date:', '', '', '', this.uploadParam.apDate, '', '', '']);
        worksheet.mergeCells('A2:D2');
        worksheet.mergeCells('E2:H2');
        worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'right' };
        worksheet.getCell('A2').font = { bold: true };
        worksheet.getCell('E2').alignment = { vertical: 'middle', horizontal: 'left' };

        worksheet.addRow(['Invoice Margin of Error %:', '', '', '', this.uploadParam.marginOfError, '', '', '']);
        worksheet.mergeCells('A3:D3');
        worksheet.mergeCells('E3:H3');
        worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'right' };
        worksheet.getCell('A3').font = { bold: true };
        worksheet.getCell('E3').alignment = { vertical: 'middle', horizontal: 'left' };

        worksheet.addRow(['Minimum Invoice Verification Amount:', '', '', '', this.uploadParam.verificationAmount, '', '', '']);
        worksheet.mergeCells('A4:D4');
        worksheet.mergeCells('E4:H4');
        worksheet.getCell('A4').alignment = { vertical: 'middle', horizontal: 'right' };
        worksheet.getCell('A4').font = { bold: true };
        worksheet.getCell('E4').alignment = { vertical: 'middle', horizontal: 'left' };

        worksheet.addRow(['Upload File:', '', '', '', this.uploadParam.fileName, '', '', '']);
        worksheet.mergeCells('A5:D5');
        worksheet.mergeCells('E5:H5');
        worksheet.getCell('A5').alignment = { vertical: 'middle', horizontal: 'right' };
        worksheet.getCell('A5').font = { bold: true };
        worksheet.getCell('E5').alignment = { vertical: 'middle', horizontal: 'left' };

        // Blank Row
        worksheet.addRow([]);

        // Add Header Row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { bold: true };
        });
        let isTotalRow = false;

        // Clean up data
        const formattedResults = this.formatResultsForExport();

        // Add Data
        formattedResults.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 2: //Date Cancelled
                    case 5: //Pay Period Start
                    case 6: //Pay Period End                        
                        if (val?.toString()) {
                            console.log(val, index);
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push("");
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);

            // Check if it is a total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Invoice')) {
                    isTotalRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If total row, set to bold
                if (isTotalRow && cell.value.toString().includes('(') && cell.value.toString().includes(')')) {
                    cell.font = { color: { argb: 'FF0000' }, bold: true };
                    cell.value = cell.value.replace('-', '');
                } else if (isTotalRow) {
                    cell.font = { bold: true };
                }
                // If negative value, set font to red
                else if (cell.value.toString().includes('(') && cell.value.toString().includes(')') && !/[a-zA-Z]/.test(cell.value.toString())) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                }
            });
            // Reset total row check
            isTotalRow = false;
        });

        this.excelService.exportAsExcelFile(workbook, 'InvoiceUploadResults');

    }

    onGettingBankToView(bankId: number): void {
        try {
            this.bankService.getBankWithCommitment(bankId)
                .subscribe((aresult: BankWithAmountTypes) => {
                    this.selectedBank = aresult;

                    //  get the bank amount types from selected bank !
                    this.bankAmountTypeService.getBankAmountTypeByBank(this.selectedBank.id)
                        .subscribe((bresult: BankAmountType[]) => {
                            this.selectedBank.amountTypes = [];
                            //  loop through the bank amoutn types result from selected bank !
                            bresult.forEach(res => {
                                this.selectedBankAmountTypes.push(res.amountTypeId.toString());
                                this.selectedBank.amountTypes.push(res.amountTypeId);
                            });

                            //  display the edit bank dialog !
                            this.displayBankEditDialog = true;
                        }, () => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Bank Amount Types Not Loaded',
                                detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
                            });

                            this.selectedBankAmountTypes = null;
                            this.displayBankEditDialog = true;
                        });
                });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occurred',
                detail: 'An error occurred during multiple process after getting the bank or surety record.'
            });
        }
    }

    onGettingBondToView(bondId: number): void {
        //  get the blc record by its ID passed !
        try {
            if (bondId === null || bondId === undefined) {
                throw new Error(`the parameter to get a bond record by its bond number is empty.`);
            }

            const selectedBond = this.results.find(bond => bond.bondId == bondId);
            this.bondService.getBondByNumber(selectedBond.bondNumber)
                .subscribe(async (result: Bond) => {
                    if (result === null || result === undefined || result?.id < 1) {
                        throw new Error(`Bond data for ${bondId} not found.`);
                    }
                    this.bondData = result;

                    //  now that one got the bon data then finish the rest of the fetches for the
                    //  BLC Edit clients' input data and emitter !
                    //  cleanup the dates in this bond data !
                    this.bondData.dateCanceled != null ?
                        this.bondData.dateCanceled = this.blcDatePipe.transform(this.bondData.dateCanceled) :
                        this.bondData.dateCanceled = null;
                    this.bondData.dateIssued != null ?
                        this.bondData.dateIssued = this.blcDatePipe.transform(this.bondData.dateIssued) :
                        this.bondData.dateIssued = null;
                    this.bondData.dateMailed != null ?
                        this.bondData.dateMailed = this.blcDatePipe.transform(this.bondData.dateMailed) :
                        this.bondData.dateMailed = null;
                    this.bondData.dateExpired != null ?
                        this.bondData.dateExpired = this.blcDatePipe.transform(this.bondData.dateExpired) :
                        this.bondData.dateExpired = null;
                    this.bondData.dateExpired != null ?
                        this.bondData.currentDateExpired = this.blcDatePipe.transform(this.bondData.dateExpired) :
                        this.bondData.currentDateExpired = null;
                    this.bondData.estimatedReleaseDate != null ?
                        this.bondData.estimatedReleaseDate = this.blcDatePipe.transform(this.bondData.estimatedReleaseDate) :
                        this.bondData.estimatedReleaseDate = null;

                    //  get the bond status !
                    const getBondStatusResult = this.commonFunctions.getBondStatus(this.bondData);

                    //  update the bond model !
                    this.bondData = getBondStatusResult.modifiedBond;

                    //  update bond type !
                    this.bondData.bondType = this.commonFunctions.getBondType(this.bondData.isLetterOfCredit);

                    //  update the status cancel !
                    this.isStatusCanceled = getBondStatusResult.isStatusCanceled;
                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                        this.bankService.getAvailableCommitment(this.bondData.bankId,
                            this.isAmountTypeFinancialGuarantee).subscribe((bankInformation) => {
                                this.bankData = bankInformation;
                                this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                            });
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                        this.bankService.getAvailableCommitment(this.bondData.bankId,
                            this.isAmountTypeFinancialGuarantee).subscribe((bankInformation) => {
                                this.bankData = bankInformation;
                                this.availableCommitment = this.bankData.availableCommitment;
                            });
                    }

                    //  determine if bond type is letter of credit !
                    this.bankService.getBanks(true, null)
                        .subscribe((banks: Bank[]) => {
                            this.banksForDialog = banks;
                            this.banksForDialog.sort((a, b) => a.name.localeCompare(b.name));
                            const result2 = this.commonFunctions.setSingleBondLimit(this.banksForDialog, this.bondData);
                            this.singleBondLimit = result2.sbl;
                        });

                    //  load the associated blob documents from SQL!
                    this.documentService.getDocumentsByBlcId(this.bondData.id)
                        .subscribe((docs: Document[]) => {
                            // set the string to a Date object so that the p-calendar on the cost to complete tab understands
                            this.documents = docs;
                        });

                    this.displayEditDialog = true;
                }, () => {
                    this.messageService.add({
                        severity: 'error', summary: 'Unexpected Error Occurred',
                        detail: 'An error occurred getting the BLC.'
                    });
                });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occurred',
                detail: 'An error occurred during multiple process after getting the BLC record.'
            });
        }
    }

    onLinkClick(event: any): void {
        const resultArray = event.currentTarget.id.split('_');
        const type = resultArray[0];
        const id = resultArray[1];

        if (type === 'bankId') {
            this.onGettingBankToView(id);
        } else if (type === 'bondId') {
            this.onGettingBondToView(id);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occurred',
                detail: 'Invalid link type on selected record'
            });
        }
    }

    onNoUpload(): void {
        if (confirm('You have chosen to abandon this upload. Is this correct? This will close the modal window.')) {
            this.closeModal();
        }
    }

    onYesUpload(): void {
        if (this.fileId > 0) {
            if (confirm('You have chosen to create ' + this.verifiedResultsCount + ' invoice record(s). Do you wish to continue?')) {
                this.invoiceService.importInvoices(this.fileId).subscribe(res => {
                    alert(res + ' invoice(s) successfully created.')
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Successful Import',
                        detail: res + ' invoice(s) successfully created.'
                    });
                    this.uploadCompleteEvent.emit();
                    this.closeModal();
                }, () => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Occurred during Invoice Creation',
                        detail: 'An unexpected error occurred while creating invoice(s) from import. Please try again.'
                    });
                });
            }
        }
    }
}
