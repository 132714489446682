import { inject, Type } from '@angular/core';

import { BLCCryptography } from '../classes/blccryptography';
import { IStorage } from '../classes/interfaces/istorage';
import { SecureStorage } from '../classes/securestorage';

export function getStorageFactory<T extends IStorage>(storageType: Type<T>) {
    const factory = () => {
        return new SecureStorage(inject(storageType), inject(BLCCryptography))
    }

    return factory;
}
