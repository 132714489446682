import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class NotificationsService {
    messageNotificationChange: Subject<Object> = new Subject<Object>();
    toastNotificationChange: Subject<Object> = new Subject<Object>();

    constructor() { }

    messageNotify(severity: Severities, summary: string, detail: string) {
        this.messageNotificationChange.next({ severity, summary, detail });
    }

    toastNotify(severity: Severities, summary: string, detail: string) {
        this.toastNotificationChange.next({ severity, summary, detail });
    }
}
