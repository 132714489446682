// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .p-dialog .p-dialog-header {
    background: #336699;
}

::ng-deep .p-dialog .p-dialog-content {
    background: #336699;
}

::ng-deep .p-dialog .p-dialog-header .p-dialog-title {
    color: #fff;
    font-size: 16px;
}

:host ::ng-deep .p-dialog-header-close-icon {
    color: white;
    font-size: 2rem;
}

:host ::ng-deep .p-inputnumber-input {
    width: 200px !important;
}

.modal-footer {
    border: none;
}

.well {
    border: solid 3px #e9c462;
}

.required:after {
    content: " *";
    color: red;
}

.err-highlight {
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/app/edit-amendment/edit-amendment.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":["::ng-deep .p-dialog .p-dialog-header {\r\n    background: #336699;\r\n}\r\n\r\n::ng-deep .p-dialog .p-dialog-content {\r\n    background: #336699;\r\n}\r\n\r\n::ng-deep .p-dialog .p-dialog-header .p-dialog-title {\r\n    color: #fff;\r\n    font-size: 16px;\r\n}\r\n\r\n:host ::ng-deep .p-dialog-header-close-icon {\r\n    color: white;\r\n    font-size: 2rem;\r\n}\r\n\r\n:host ::ng-deep .p-inputnumber-input {\r\n    width: 200px !important;\r\n}\r\n\r\n.modal-footer {\r\n    border: none;\r\n}\r\n\r\n.well {\r\n    border: solid 3px #e9c462;\r\n}\r\n\r\n.required:after {\r\n    content: \" *\";\r\n    color: red;\r\n}\r\n\r\n.err-highlight {\r\n    color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
