import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

import { DataCacheObject } from '../../models';
import { ClientDataCacheService, ServerDataCacheService } from '../../services';

@Component({
    selector: 'app-data-cache-manager',
    templateUrl: './data-cache-manager.component.html',
    styleUrls: ['./data-cache-manager.component.css']
})
export class DataCacheManagerComponent implements OnInit {

    cols: any[];
    dataCacheList: DataCacheObject[];

    private downloadSetting = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    };

    constructor(private clientDataCacheService: ClientDataCacheService, private datePipe: DatePipe, private messageService: MessageService, private serverDataCacheService: ServerDataCacheService) { }

    ngOnInit(): void {
        this.cols = [
            { field: 'name', subfield: '', header: 'Name' },
            { field: 'cacheTime', subfield: '', header: 'Time Cached' },
            { field: 'expireTime', subfield: '', header: 'Expiry Time' },
            { field: '', subfield: '', header: '' },
        ];

        this.getDataCaches();
    }

    getDataCaches(): void {
        this.serverDataCacheService.getDataCache().subscribe((data) => {
            this.dataCacheList = data;
        });
    }

    onDownloadAllCache(): void {
        this.downloadFile({
            fileName: 'FullCache_' + this.datePipe.transform(new Date(), 'MM-dd-yyyy') + '.json',
            text: JSON.stringify(this.dataCacheList, null, '\t') // required to make json pretty format
        });
    }

    onDownloadCache(dataCache: DataCacheObject): void {
        this.downloadFile({
            fileName: 'Cache_' + dataCache.name + this.datePipe.transform(new Date(), 'MM-dd-yyyy') + '.json',
            text: JSON.stringify(dataCache, null, '\t') // required to make json pretty format
        });
    }

    onRefreshTable(): void {
        this.getDataCaches();
    }

    onRemoveAllCache(): void {
        if (confirm('Are you sure you want to delete the full data cache?')) {
            this.clientDataCacheService.removeAngularCache('all');
            this.serverDataCacheService.removeAllDataCache().subscribe(() => {
                this.messageService.add({
                    severity: 'success', summary: 'Cache Dropped',
                    detail: `Successfully dropped all of cached data`
                });
                this.getDataCaches();
            });
        }
    }

    onRemoveCache(dataCache: DataCacheObject): void {
        if (confirm('Are you sure you want to delete the cache for ' + dataCache.name + '?')) {
            this.clientDataCacheService.removeAngularCache(dataCache.name);
            this.serverDataCacheService.removeDataCacheByKey(dataCache.name).subscribe(() => {
                this.messageService.add({
                    severity: 'success', summary: 'Cache Dropped',
                    detail: `Successfully dropped cache ${dataCache.name}`
                });
                this.getDataCaches();
            });
        }
    }

    private downloadFile(arg: { fileName: string, text: string }): void {
        if (!this.downloadSetting.element.dynamicDownload) {
            this.downloadSetting.element.dynamicDownload = document.createElement('a');
        }
        const element = this.downloadSetting.element.dynamicDownload;
        const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
        element.setAttribute('download', arg.fileName);

        const event = new MouseEvent('click');
        element.dispatchEvent(event);

        this.messageService.add({
            severity: 'success', summary: 'Cache Downloaded',
            detail: `Successfully downloaded cache file ${arg.fileName}`
        });
    }
}
