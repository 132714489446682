export class DocumentParam {
    documentId: string;
    blobName: string;
    contentType: string;
    base64Content: string;
    fileDescription: string;
    uploadBy: string;
    fileType: string;
    blcId: number;
}
