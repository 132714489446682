import { Component, OnInit } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs/operators';

import { CommonFunctions } from '../../../functions';
import { AmountType, BankAmountType, BankWithAmountTypes, LcSuretyBondPricingAnalysis, ReportColumn, TotalRowData } from '../../../models';
import { BlcDatePipe } from '../../../../src/pipes';

import { AmountTypeService, AuthorizationService, BankAmountTypeService, BankService, ExcelService, ReportService } from '../../../services';

@Component({
    selector: 'app-lc-surety-bond-pricing-analysis',
    templateUrl: './lc-surety-bond-pricing-analysis.component.html',
    styleUrls: ['./lc-surety-bond-pricing-analysis.component.css']
})
export class LcSuretyBondPricingAnalysisComponent implements OnInit {
    amountTypeList: AmountType[] = [];
    asOfDate: Date;
    cols: ReportColumn[] = [];
    decimalFormat: any = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    displayBankEditDialog: boolean = false;
    exportFileName: string;
    exportHeader: string[];
    filteredList: LcSuretyBondPricingAnalysis[];
    formattedExportList: any[] = [];
    isAsOfDateValid: boolean = true;
    reportTitle: string;
    results: LcSuretyBondPricingAnalysis[];
    selectedBank: BankWithAmountTypes;
    selectedBankAmountTypes: string[] = [];
    showNoResultsMessage: boolean = false;
    showResults: boolean = false;
    sub: any = null;
    totalRowData: TotalRowData;

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private blcDatePipe: BlcDatePipe,
        private commonFunctions: CommonFunctions,
        private excelService: ExcelService,
        private messageService: MessageService,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess();
        this.asOfDate = new Date();

        //  load amount types !
        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occurred',
                    detail: 'An error occurred getting Amount Types.'
                });
            });

        this.cols = [
            { field: 'bank', header: 'Name', link: 'bankId', widthPercentage: 10 },
            { field: 'broker', header: 'Broker', widthPercentage: 5 },
            { field: 'dateIndemnityAgreement', header: 'Agreement Date', dataType: 'date' },
            { field: 'isActive', header: 'Active', dataType: 'boolean' },
            { field: 'commitAmount', header: 'Commitment Amount', dataType: 'currency', widthPercentage: 10 },
            { field: 'financialGuaranteeCommitAmount', header: 'Financial Guarantee Commitment', dataType: 'currency', widthPercentage: 7 },
            { field: 'singleBondLimit', header: 'Per Bond Limit', dataType: 'currency', widthPercentage: 7 },
            { field: 'isPowerOfAttorney', header: 'PA', dataType: 'boolean' },
            { field: 'amountTypes', header: 'Bond/LC Types', lineBreakCharacter: ',' },
            { field: 'outstandingAmount', header: 'Outstanding Total', dataType: 'currency', widthPercentage: 7 },
            { field: 'rate', header: 'Rate (bps)' },
            { field: 'financialGuaranteeRate', header: 'FG Rate (bps)' },
            { field: 'minimumFee', header: 'Minimum Fee', dataType: 'currency' },
            { field: 'terms', header: 'Terms', widthPercentage: 10 },
            { field: 'description', header: 'Notes', widthPercentage: 10 }
        ];

        this.exportHeader = ['Name', 'Broker', 'Agreement Date', 'Active', 'Commitment Amount', 'Financial Guarantee Amount',
            'Per Bond Limit', 'PA', 'Bond & LC Types', 'Outstanding Total', 'Rate (bps)', 'FG Rate (bps)', 'Minimum Fee', 'Terms', 'Notes'];

        this.exportFileName = 'PricingAnalysis';
    }

    exportExcel(): void {
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');

        // Add title row
        const titleRow = worksheet.addRow([this.reportTitle]);
        worksheet.mergeCells('A1:O1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

        // Blank Row
        worksheet.addRow([]);

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 15;
        });

        // Add Header Row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { bold: 'true' };

            if (cell.value.includes('Name')) {
                cell._column.width = 35;
            } else if (cell.value.includes('Broker')) {
                cell._column.width = 17;
            } else if (cell.value.includes('Agreement Date')) {
                cell._column.width = 12;
            } else if (cell.value.includes('Active')) {
                cell._column.width = 8;
            } else if (cell.value.includes('Commitment Amount')) {
                cell._column.width = 17;
            } else if (cell.value.includes('Financial Guarantee Amount')) {
                cell._column.width = 17;
            } else if (cell.value.includes('Per Bond Limit')) {
                cell._column.width = 17;
            } else if (cell.value.includes('PA')) {
                cell._column.width = 2;
            } else if (cell.value.includes('Bond & LC Types')) {
                cell._column.width = 18;
            } else if (cell.value.includes('Outstanding Total')) {
                cell._column.width = 17;
            } else if (cell.value.includes('Rate')) { // Covers Rate and FG Rate columns
                cell._column.width = 6;
            } else if (cell.value.includes('Minimum Fee')) {
                cell._column.width = 10;
            } else if (cell.value.includes('Terms')) {
                cell._column.width = 30;
            } else if (cell.value.includes('Notes')) {
                cell._column.width = 55;
            }
        });

        // Add Data
        this.formattedExportList.forEach(dataRow => {
            const dataStrings: any[] = [];

            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 0: //Primary Key ID -- skip
                        break;
                    case 3: //Agreement Date
                        if (val?.toString()) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row            
            const row = worksheet.addRow(dataStrings);
            row.height = 100;
            row.alignment = { wrapText: true, vertical: 'top' };

            row.eachCell((cell) => {
                // If negative value, set font to red
                if (cell.value.toString().includes('(') && cell.value.toString().includes(')') && !isNaN(cell.value.toString().replace(/[^a-zA-Z0-9 ]/gi, '').trim())) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                }
            });
        });

        // Format total row object
        const totalRowObj = {
            name: 'Surety Bond Totals:',
            broker: '',
            agreementDate: '',
            isActive: '',
            commitAmount: +this.totalRowData.totalCommitmentAmount < 0 ?
                '($' + ((+this.totalRowData.totalCommitmentAmount).toLocaleString('en', this.decimalFormat)).substring(1) + ')' :
                '$' + (+this.totalRowData.totalCommitmentAmount).toLocaleString('en', this.decimalFormat),
            financialGuaranteeCommitAmount: +this.totalRowData.totalFGCommitment < 0 ?
                '($' + ((+this.totalRowData.totalFGCommitment).toLocaleString('en', this.decimalFormat)).substring(1) + ')' :
                '$' + (+this.totalRowData.totalFGCommitment).toLocaleString('en', this.decimalFormat),
            singleBondLimit: '',
            isPowerOfAttorney: '',
            amountTypes: '',
            outstandingAmount: +this.totalRowData.totalOsTotal < 0 ?
                '($' + ((+this.totalRowData.totalOsTotal).toLocaleString('en', this.decimalFormat)).substring(1) + ')' :
                '$' + (+this.totalRowData.totalOsTotal).toLocaleString('en', this.decimalFormat),
            rate: this.totalRowData.averageTotalRate.toLocaleString('en', this.decimalFormat),
            financialGuaranteeRate: this.totalRowData.averageTotalFgRate.toLocaleString('en', this.decimalFormat),
            minimumFee: '(averages)',
            terms: '',
            description: ''
        };

        // Add total row
        let dataStrings: string[] = [];
        // Convert each prop into a string array
        Object.values(totalRowObj).forEach((val: string) => {
            dataStrings.push(val);
        });
        const totalRow = worksheet.addRow(dataStrings);

        // Format total row
        totalRow.eachCell((cell) => {
            cell.font = { bold: 'true' };
        });

        // Format final row object
        const finalRowObj = {
            name: 'Average Bond Pricing',
            broker: '',
            agreementDate: '',
            isActive: '',
            commitAmount: '',
            financialGuaranteeCommitAmount: '',
            singleBondLimit: '',
            isPowerOfAttorney: '',
            amountTypes: '',
            outstandingAmount: '',
            rate: this.totalRowData.averageBondPricing.toLocaleString('en', this.decimalFormat),
            financialGuaranteeRate: '',
            minimumFee: '',
            terms: '',
            description: ''
        };

        // Add final row
        dataStrings = [];
        // Convert each prop into a string array
        Object.values(finalRowObj).forEach((val: string) => {
            dataStrings.push(val);
        });
        const finalRow = worksheet.addRow(dataStrings);

        // Format final row
        const finalRowNumber = finalRow._number;
        worksheet.mergeCells(`A${finalRowNumber}:J${finalRowNumber}`);
        worksheet.getCell(`A${finalRowNumber}`).alignment = { vertical: 'middle', horizontal: 'right' };

        finalRow.eachCell((cell) => {
            cell.font = { bold: 'true' };
        });

        this.excelService.exportAsExcelFile(workbook, this.exportFileName);
    }

    onAsOfDateChanged(value: any) {
        if (value !== null && value !== '' && value !== undefined && this.commonFunctions.isMMDDYYYYDate(value)) {
            this.isAsOfDateValid = true;
        } else {
            this.isAsOfDateValid = false;
            this.asOfDate = null;
        }
    }

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onGettingBankToView(bankId: number): void {
        try {
            this.bankService.getBankWithCommitment(bankId).pipe(
                switchMap((bankWithAmountTypes: BankWithAmountTypes) => {
                    this.selectedBank = bankWithAmountTypes;

                    //  get the bank amount types from selected bank !
                    return this.bankAmountTypeService.getBankAmountTypeByBank(this.selectedBank.id);
                })
            ).subscribe((bankAmountType: BankAmountType[]) => {
                this.selectedBank.amountTypes = [];
                //  loop through the bank amoutn types result from selected bank !
                bankAmountType.forEach(res => {
                    this.selectedBankAmountTypes.push(res.amountTypeId.toString());
                    this.selectedBank.amountTypes.push(res.amountTypeId);
                });
                //  display the edit bank dialog !
                this.displayBankEditDialog = true;
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Bank Amount Types Not Loaded',
                    detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
                });
                this.selectedBankAmountTypes = null;
                this.displayBankEditDialog = true;
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occurred',
                detail: 'An error occurred during multiple process after getting the bank or surety record.'
            });
        }
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const id = resultArray[1];

        this.onGettingBankToView(id);
    }

    onResetClick() {
        this.asOfDate = new Date();
        this.isAsOfDateValid = true;
        this.showResults = false;
    }

    onSearchClick() {
        if (this.isAsOfDateValid === false) {
            if (this.asOfDate === null) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid As Of Date',
                    detail: 'Please enter an As Of Date.'
                });
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid As Of Date',
                    detail: 'Please enter a valid As Of Date.'
                });
            }
        } else {
            this.showResults = false;
            this.showNoResultsMessage = false;
            this.results = [];
            this.formattedExportList = [];
            this.totalRowData = new TotalRowData();

            const dateParam = this.blcDatePipe.transform(this.asOfDate, 'MM-dd-yyyy');
            this.reportTitle = 'Letters of Credit / Surety Bond Pricing Analysis as of ' + dateParam;

            this.reportService.getLcSuretyBondPricingAnalysisReport(dateParam)
                .subscribe(res => {
                    this.results = res;

                    this.results.forEach(result => {
                        // Format the date objects to remove timestamps
                        this.commonFunctions.trimTimeFromDatePropertiesInObject(result);

                        const formattedRes = {
                            bankId: result.bankId,
                            name: result.bank,
                            broker: result.broker,
                            agreementDate: result.dateIndemnityAgreement,
                            isActive: result.isActive ? 'Y' : 'N',
                            commitAmount: +result.commitAmount < 0 ?
                                '($' + ((+result.commitAmount).toLocaleString('en',
                                    this.decimalFormat)).substring(1) + ')' :
                                '$' + (+result.commitAmount).toLocaleString('en',
                                    this.decimalFormat),
                            financialGuaranteeCommitAmount: +result.financialGuaranteeCommitAmount < 0 ?
                                '($' + ((+result.financialGuaranteeCommitAmount).toLocaleString('en',
                                    this.decimalFormat)).substring(1) + ')' :
                                '$' + (+result.financialGuaranteeCommitAmount).toLocaleString('en',
                                    this.decimalFormat),
                            singleBondLimit: +result.singleBondLimit < 0 ?
                                '($' + ((+result.singleBondLimit).toLocaleString('en',
                                    this.decimalFormat)).substring(1) + ')' :
                                '$' + (+result.singleBondLimit).toLocaleString('en',
                                    this.decimalFormat),
                            isPowerOfAttorney: result.isPowerOfAttorney ? 'Y' : 'N',
                            amountTypes: result.amountTypes,
                            outstandingAmount: +result.outstandingAmount < 0 ?
                                '($' + ((+result.outstandingAmount).toLocaleString('en',
                                    this.decimalFormat)).substring(1) + ')' :
                                '$' + (+result.outstandingAmount).toLocaleString('en',
                                    this.decimalFormat),
                            rate: result.rate,
                            financialGuaranteeRate: result.financialGuaranteeRate,
                            minimumFee: +result.minimumFee < 0 ?
                                '($' + ((+result.minimumFee).toLocaleString('en',
                                    this.decimalFormat)).substring(1) + ')' :
                                '$' + (+result.minimumFee).toLocaleString('en',
                                    this.decimalFormat),
                            terms: result.terms?.replace('\r\n', '\s'),
                            description: result.description?.replace('\r\n', '\s')
                        };
                        this.formattedExportList.push(formattedRes);

                        this.totalRowData.totalCommitmentAmount += result.commitAmount;
                        this.totalRowData.totalFGCommitment += result.financialGuaranteeCommitAmount;
                        this.totalRowData.totalOsTotal += result.outstandingAmount;
                        this.totalRowData.totalRate += result.rate;
                        this.totalRowData.totalRecords++;

                        if (result.financialGuaranteeRate && result.financialGuaranteeRate != 0) {
                            this.totalRowData.totalFgRate += result.financialGuaranteeRate;
                            this.totalRowData.totalFgrRecords++;
                        }
                    });

                    //  after the loop, add the summary totals thus far!
                    var totalsRes = new LcSuretyBondPricingAnalysis();
                    totalsRes.bankId = null;
                    totalsRes.bank = null;
                    totalsRes.broker = 'Surety Bond Totals:';
                    totalsRes.dateIndemnityAgreement = null;
                    totalsRes.isActive = null;
                    totalsRes.commitAmount = this.totalRowData.totalCommitmentAmount;
                    totalsRes.financialGuaranteeCommitAmount = this.totalRowData.totalFGCommitment;
                    totalsRes.singleBondLimit = null;
                    totalsRes.isPowerOfAttorney = null;
                    totalsRes.amountTypes = null;
                    totalsRes.outstandingAmount = this.totalRowData.totalOsTotal;
                    totalsRes.rate = (this.totalRowData.averageTotalRate) ? parseFloat(this.totalRowData.averageTotalRate.toFixed(2)) : null;
                    totalsRes.financialGuaranteeRate = (this.totalRowData.averageTotalFgRate) ? parseFloat(this.totalRowData.averageTotalFgRate.toFixed(2)) : null;
                    totalsRes.minimumFee = null;
                    totalsRes.terms = null;
                    totalsRes.description = '(averages)';
                    totalsRes.groupKey = 6000;
                    this.results.push(totalsRes);

                    totalsRes = new LcSuretyBondPricingAnalysis();
                    totalsRes.bankId = null;
                    totalsRes.bank = null;
                    totalsRes.broker = null;
                    totalsRes.dateIndemnityAgreement = null;
                    totalsRes.isActive = null;
                    totalsRes.commitAmount = null;
                    totalsRes.financialGuaranteeCommitAmount = null;
                    totalsRes.singleBondLimit = null;
                    totalsRes.isPowerOfAttorney = null;
                    totalsRes.amountTypes = 'Average Bond Pricing:';
                    totalsRes.outstandingAmount = null;
                    totalsRes.rate = (this.totalRowData.averageBondPricing) ? parseFloat(this.totalRowData.averageBondPricing.toFixed(2)) : null;
                    totalsRes.financialGuaranteeRate = null;
                    totalsRes.minimumFee = null;
                    totalsRes.terms = null;
                    totalsRes.description = null;
                    totalsRes.groupKey = 7000;
                    this.results.push(totalsRes);

                    //  end of data load for report and excel !
                    this.showResults = true;
                    // This list is to exclude the last rows of data i.e. Totals in the matches count
                    this.filteredList = this.results.filter(function (value) {
                        return value.bankId && value.bank !== null;
                    });
                    if (this.results.length === 0) {
                        this.showNoResultsMessage = true;
                    }
                });
        }
    }
}
