import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lib-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
