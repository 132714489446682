import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'blcDate' })
export class BlcDatePipe implements PipeTransform {
    transform(date: Date | string, format: string = 'MM/dd/yyyy'): string {
        var retval: string;
        if (date) {
            const dateWeGet = new Date(date);
            const utcDate = new Date(
                Date.UTC(
                    dateWeGet.getUTCFullYear(),
                    dateWeGet.getUTCMonth(),
                    dateWeGet.getUTCDate(),
                    dateWeGet.getUTCHours(),
                    dateWeGet.getUTCMinutes(),
                    dateWeGet.getUTCSeconds()
                )
            );
            const dtSplitter = utcDate.toISOString().split(`T`);
            const dtSplitterLevel2 = dtSplitter[0].split(`-`);
            var dateFinal = dtSplitterLevel2[1] + `/` + dtSplitterLevel2[2] + `/` + dtSplitterLevel2[0];
            retval = new DatePipe('en-US').transform(dateFinal, format);
        } else {
            retval = '';
        }
        return retval;
    }

    adjustForTimezone(date: Date): Date {
        var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }
}