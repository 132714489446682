import { Pipe, PipeTransform } from '@angular/core';
import { AmountType } from '../models';

@Pipe({ name: 'amountTypeName' })
export class AmountTypeNamePipe implements PipeTransform {
    transform(amountTypeId: number, amountTypeList: AmountType[]): string {
        if (amountTypeList?.length > 0) {
            const result = amountTypeList.find(element => element.id === amountTypeId);
            return result?.name;
        }

        return null;
    }
}
