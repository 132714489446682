import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService, PrimeNGConfig } from 'primeng/api';

import { CommonFunctions } from '../../../functions';
import { AmountType, BankAmountType, BankAndSuretyListing, BankAndSuretyListingParam, BankWithAmountTypes, Broker } from '../../../models';
import {
    AmountTypeService,
    AuthorizationService,
    BankAmountTypeService,
    BankService,
    BrokerService,
    ExcelService,
    ReportService,
} from '../../../services';
import { BlcDatePipe } from '../../../../src/pipes';
import { ReportResultsComponent } from '../report-results/report-results.component';

@Component({
    selector: 'app-bank-and-surety-listing',
    templateUrl: './bank-and-surety-listing.component.html',
    styleUrls: ['./bank-and-surety-listing.component.css'],
    providers: [MessageService]
})

export class BankAndSuretyListingComponent implements OnInit {
    @ViewChild(ReportResultsComponent) resultsComp: ReportResultsComponent;

    activeStatusList: any[] = [
        { name: `-- All --`, data: `-1` },
        { name: `Inactive`, data: `0` },
        { name: `Active`, data: `1` }
    ];
    amountTypeList: AmountType[] = [];
    bankAndSuretyListingParam: BankAndSuretyListingParam = new BankAndSuretyListingParam();
    brokerList: Broker[] = [];
    cols: any[];
    displayBankEditDialog: boolean = false;
    editBankViewOnly: boolean = true;
    excelResults: any[] = [];
    exportFileName: string = '';
    exportHeader: string[] = [];
    fromDate: Date;
    isBrokerDisabled: boolean = false;
    isLoading: boolean = false;
    isRevolverStatusDisabled: boolean = false;
    proxyActiveStatus: string;
    proxyBroker: string;
    proxyRevolverStatus: string;
    proxyType: string;
    reportGridList: any[] = [];
    revolverStatusList: any[] = [
        { name: `-- All --`, data: `-1` },
        { name: `Revolver`, data: `0` },
        { name: `Non-revolver`, data: `1` }
    ];
    reportTitle: string = '';
    reportType: string = '';
    resultSet: any[] = [];
    resultSetFooter: any;
    selectedBank: BankWithAmountTypes;
    selectedBankAmountTypes: string[] = [];
    showNoResultsMessage: boolean = false;
    showResults: boolean = false;
    sub: any = null;
    thruDate: Date;
    typeList: any[] = [
        { name: `-- All --`, data: `-1` },
        { name: `Bank`, data: `0` },
        { name: `Surety`, data: `1` }
    ];

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private blcDatePipe: BlcDatePipe,
        private brokerService: BrokerService,
        private commonFunctions: CommonFunctions,
        private excelService: ExcelService,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess();

        //  load the look up broker list !
        this.brokerService.getBrokers()
            .subscribe((result: Broker[]) => {
                const defaultBroker = new Broker();
                defaultBroker.id = -1;
                defaultBroker.name = `-- All --`;
                this.brokerList.push(defaultBroker);
                result.sort((a, b) => a.name.localeCompare(b.name));
                for (const mybroker of result) {
                    this.brokerList.push(mybroker);
                }
            });
        this.primengConfig.ripple = true;
        this.resetSomeSelections();
        this.proxyActiveStatus = '1';

        //  load amount types !
        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occurred',
                    detail: 'An error occurred getting Amount Types.'
                });
            });
    }

    dateRangeValidator(fDate: Date, tDate: Date): any {
        if (fDate !== null && tDate !== null) {
            if (fDate > tDate) {
                return { valid: false, msg: `Please specify a From and To date range with 'From Agreement Date' less than the 'To Agreement Date'` };
            }
        }
        return { valid: true, msg: `Date range are valid.` };
    };

    exportExcel(): void {
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');

        // Add title row
        const titleRow = worksheet.addRow([this.reportTitle]);
        worksheet.mergeCells('A1:L1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

        // Blank Row
        worksheet.addRow([]);

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 15;
        });

        // Add Header Row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { weight: 'bold' };
        });

        let isTotalRow = false;
        // Add Data
        this.excelResults.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 3: //Agreement Date
                        if (val?.toString()) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }));

            // If previous row was eoy total row, current row is total item string
            if (isTotalRow) {
                // Set total item string row to bold
                row.eachCell((cell) => {
                    cell.font = { bold: true };
                });

                // Reset totalRow check and add black space between years
                isTotalRow = false;
                worksheet.addRow();
                return;
            }

            // Check if it is a eoy total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Total ')) {
                    isTotalRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If eoy total row, set to bold
                if (isTotalRow) {
                    cell.font = { bold: true };
                }

                // If negative value, set font to red
                if (typeof cell.value === 'string' && cell.value.toString().includes('(') && cell.value.toString().includes(')') && !isNaN(cell.value.toString().replace(/[^a-zA-Z0-9 ]/gi, '').trim())) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                }
            });

        });

        this.excelService.exportAsExcelFile(workbook, this.exportFileName);
    };

    onActiveStatusChange(): void {
        if (Number(this.proxyActiveStatus) == -1) {
            this.bankAndSuretyListingParam.isActive = null;
        } else if (Number(this.proxyActiveStatus) == 0) {
            this.bankAndSuretyListingParam.isActive = false;
        } else {
            this.bankAndSuretyListingParam.isActive = true;
        }
    };

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onBankTypeChange(): void {
        if (Number(this.proxyType) == -1) {
            this.bankAndSuretyListingParam.isSurety = null;
            this.isRevolverStatusDisabled = false;
            this.isBrokerDisabled = false;
        } else if (Number(this.proxyType) == 0) {
            this.bankAndSuretyListingParam.isSurety = false;
            this.isRevolverStatusDisabled = false;
            this.isBrokerDisabled = true;
            this.proxyBroker = '-1';
        } else {
            this.bankAndSuretyListingParam.isSurety = true;
            this.isRevolverStatusDisabled = true;
            this.proxyRevolverStatus = '-1';
            this.isBrokerDisabled = false;
        }
        this.onRevolverStatusChange();
        this.onBrokerChange();
    };

    onBrokerChange(): void {
        if (Number(this.proxyBroker) == -1) {
            this.bankAndSuretyListingParam.brokerId = null;
        } else {
            this.bankAndSuretyListingParam.brokerId = Number(this.proxyBroker);
        }
    };

    onDateChange(): void {
        const dValidate = this.dateRangeValidator(this.fromDate, this.thruDate);
        if (dValidate.valid === false) {
            alert(dValidate.msg);
            return;
        }
    };

    onGettingBankToView(bankId: number): void {
        this.bankService.getBankWithCommitment(bankId)
            .subscribe((aresult: BankWithAmountTypes) => {
                this.selectedBank = aresult;

                //  get the bank amount types from selected bank !
                this.bankAmountTypeService.getBankAmountTypeByBank(this.selectedBank.id)
                    .subscribe((bresult: BankAmountType[]) => {
                        this.selectedBank.amountTypes = [];
                        //  loop through the bank amoutn types result from selected bank !

                        bresult.forEach(res => {
                            this.selectedBankAmountTypes.push(res.amountTypeId.toString());
                            this.selectedBank.amountTypes.push(res.amountTypeId);
                        });

                        //  display the edit bank dialog !
                        this.displayBankEditDialog = true;
                    }, () => {
                        this.selectedBankAmountTypes = null;
                        this.displayBankEditDialog = true;
                        this.messageService.add({
                            severity: 'error', summary: 'Unexpected Error Occurred',
                            detail: 'An error occurred getting a bank or surety to edit.'
                        });
                    });
            });
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const id = resultArray[1];

        this.onGettingBankToView(id);
    }

    onResetClick(): void {
        this.resetSomeSelections();
    };

    onRevolverStatusChange(): void {
        if (Number(this.proxyRevolverStatus) == -1) {
            this.bankAndSuretyListingParam.isRevolver = null;
        } else if (Number(this.proxyRevolverStatus) == 0) {
            this.bankAndSuretyListingParam.isRevolver = true;
        } else {
            this.bankAndSuretyListingParam.isRevolver = false;
        }
    };

    onSearchClick(): void {
        //  validate date range if entered !
        const dValidate = this.dateRangeValidator(this.fromDate, this.thruDate);
        if (dValidate.valid === false) {
            alert(dValidate.msg);
            return;
        }

        // Reset results and start loading
        this.showResults = false;
        this.isLoading = true;

        // Set search params and report results title
        this.reportTitle = (this.fromDate != null) || (this.thruDate != null) ? `Bank And Surety Listing from ${this.blcDatePipe.transform(this.fromDate)} through ${this.blcDatePipe.transform(this.thruDate)}` : `Bank And Surety Listing`;

        // Set result table columns
        this.cols = [
            { field: 'bankOrSurety', header: 'B/S', widthPercentage: 5 },
            { field: 'bankOrSuretyName', header: 'Name', link: 'bankId', widthPercentage: 10 },
            { field: 'brokerName', header: 'Broker', widthPercentage: 10 },
            { field: 'iaDate', header: 'Agreement Date', dataType: 'date' },
            { field: 'active', header: 'Active' },
            { field: 'powerOfAttorney', header: 'PA' },
            { field: 'revolver', header: 'RB' },
            { field: 'bankAmountTypes', header: 'Bond & LC Types Offered', widthPercentage: 15 },
            { field: 'commitAmount', header: 'Commitment Amt', dataType: 'currency', widthPercentage: 12 },
            { field: 'rate', header: 'Rate' },
            { field: 'fgCommitAmount', header: 'FG Commitment', dataType: 'currency', widthPercentage: 10 },
            { field: 'fgRate', header: 'FG Rate' }
        ];

        // Set export file name
        this.exportFileName = 'BankAndSuretyListing';

        // Reset the header row
        this.exportHeader = [];

        // Set header row values for export
        for (const col of this.cols) {
            this.exportHeader.push(col.header);
        }

        this.bankAndSuretyListingParam.fromDateIndemnityAgreement = this.blcDatePipe.transform(this.fromDate);
        this.bankAndSuretyListingParam.toDateIndemnityAgreement = this.blcDatePipe.transform(this.thruDate);
        this.onActiveStatusChange();
        this.reportService.getBankAndSuretyListing(this.bankAndSuretyListingParam)
            .subscribe((result: BankAndSuretyListing[]) => {
                const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
                this.reportGridList = [];
                this.excelResults = [];

                for (const bas of result) {
                    this.commonFunctions.trimTimeFromDatePropertiesInObject(bas);
                    const d_amtTypes: string[] = [];
                    for (const s of bas?.amountTypes.split(',')) {
                        d_amtTypes.push(s);
                    }
                    const d_commitAmount = (bas.commitAmount) ? bas.commitAmount.toLocaleString('en', decimalFormat) : `0.00`;
                    const d_fgCommitAmount = (bas.financialGuaranteeCommitAmount) ? bas.financialGuaranteeCommitAmount.toLocaleString('en', decimalFormat) : `0.00`;
                    const d_fgRate = (bas.financialGuaranteeRate) ? bas.financialGuaranteeRate.toLocaleString('en', decimalFormat) : `0.00`;
                    const d_rate = (bas.rate) ? bas.rate.toLocaleString('en', decimalFormat) : `0.00`;

                    //  this result set here is the one sent to the generic table component !
                    this.reportGridList.push({
                        bankOrSurety: bas.isSurety ? `S` : `B`,
                        bankOrSuretyName: bas.name,
                        name: bas.name,
                        brokerName: bas.brokerName,
                        bankId: bas.id,
                        iaDate: bas.dateIndemnityAgreement,
                        active: bas.isActive ? `Y` : ``,
                        powerOfAttorney: bas.isPowerOfAttorney ? `Y` : ``,
                        revolver: bas.isRevolver ? `Y` : ``,
                        bankAmountTypes: d_amtTypes,
                        commitAmount: (bas.commitAmount) ? parseFloat(bas.commitAmount.toFixed(2)) : null,
                        rate: bas.rate,
                        fgCommitAmount: (bas.financialGuaranteeCommitAmount) ? parseFloat(bas.financialGuaranteeCommitAmount.toFixed(2)) : null,
                        fgRate: bas.financialGuaranteeRate
                    });

                    //  result set load for Excel exports !
                    this.excelResults.push({
                        bankOrSurety: bas.isSurety ? `S` : `B`,
                        bankOrSuretyName: bas.name,
                        brokerName: bas.brokerName,
                        iaDate: bas.dateIndemnityAgreement,
                        active: bas.isActive ? `Y` : ``,
                        powerOfAttorney: bas.isPowerOfAttorney ? `Y` : ``,
                        revolver: bas.isRevolver ? `Y` : ``,
                        bankAmountTypes: bas.amountTypes,
                        commitAmount: +bas.commitAmount < 0 ?
                            `($${d_commitAmount})` :
                            `$${d_commitAmount}`,
                        rate: `${d_rate}`,
                        fgCommitAmount: +bas.financialGuaranteeCommitAmount < 0 ?
                            `($${d_fgCommitAmount})` :
                            `$${d_fgCommitAmount}`,
                        fgRate: `${d_fgRate}`
                    });
                }

                // End loading
                this.showResults = true;
                if (this.excelResults.length === 0) {
                    this.showNoResultsMessage = true;
                }
                this.isLoading = false;
            });
    };

    resetSomeSelections(): void {
        this.proxyActiveStatus = `-1`;
        this.proxyBroker = `-1`;
        this.proxyRevolverStatus = `-1`;
        this.proxyType = `-1`;
        this.fromDate = null;
        this.thruDate = null;
        this.isRevolverStatusDisabled = false;
        this.isBrokerDisabled = false;
        this.bankAndSuretyListingParam = new BankAndSuretyListingParam();
    };
}
