import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Bond, BondsFilter, InvoiceBanksBonds, UpdateBondRateParam } from './../../models';
import { environment } from '../../../src/environments/environment';
import { CommonFunctions } from '../../../src/functions';

import { SharedService } from './shared.service';

@Injectable()
export class BondService {

    constructor(private commonFunctions: CommonFunctions, public http: HttpClient, private sharedService: SharedService) { }

    addBond(blc: Bond): Observable<Bond> {
        const opts = this.sharedService.getOptsApplication();

        const id = blc.id ? blc.id : null;
        const amount = blc.amount ? blc.amount : '';
        const amountDescription = blc.amountDescription ? this.sharedService.replaceAmpersand(blc.amountDescription) : '';
        const amountTypeId = blc.amountTypeId ? blc.amountTypeId : '';
        const amountSubTypeId = blc.amountSubTypeId ? blc.amountSubTypeId : '';
        const bankId = blc.bankId ? blc.bankId : '';
        const beneficiary = blc.beneficiary ? this.sharedService.replaceAmpersand(blc.beneficiary) : '';
        const communityNumber = blc.communityNumber ? blc.communityNumber : '';
        const marketNumber = blc.marketNumber ? blc.marketNumber : '';
        const dateCanceled = blc.dateCanceled ? blc.dateCanceled : null;
        const dateExpired = blc.dateExpired ? this.commonFunctions.formatDateMmddyyyy(blc.dateExpired.toString()) : null;
        const dateIssued = blc.dateIssued ? this.commonFunctions.formatDateMmddyyyy(blc.dateIssued.toString()) : null;
        const dateMailed = blc.dateMailed ? this.commonFunctions.formatDateMmddyyyy(blc.dateMailed.toString()) : null;
        const description = blc.description ? this.sharedService.replaceAmpersand(blc.description) : '';
        const isAutoRenew = blc.isAutoRenew ? blc.isAutoRenew : '';
        const isLetterOfCredit = Number(blc.isLetterOfCredit) === 1 ? true : false;
        const modifiedBy = blc.modifiedBy ? blc.modifiedBy : 'BLC System';
        const number = blc.number ? blc.number : '';
        const rate = blc.rate ? blc.rate : '';
        const LDAPhaseCode = blc.ldaPhaseCode ? blc.ldaPhaseCode : null;
        const subs = 'id=' + id + '&amount=' + amount + '&amountDescription=' + amountDescription + '&amountTypeId=' + amountTypeId +
            '&amountSubTypeId=' + amountSubTypeId + '&bankId=' + bankId + '&beneficiary=' + beneficiary + '&communityNumber=' + communityNumber +
            '&MarketNumber=' + marketNumber + '&dateCanceled=' + dateCanceled + '&dateExpired=' + dateExpired + '&dateIssued=' + dateIssued +
            '&dateMailed=' + dateMailed + '&description=' + description + '&isAutoRenew=' + isAutoRenew + '&isLetterOfCredit=' + isLetterOfCredit +
            '&modifiedBy=' + modifiedBy + '&number=' + number + '&rate=' + rate + '&LDAPhaseCode=' + LDAPhaseCode;

        return this.http.post<Bond>(environment.baseUrl + `Bonds/CreateBond?`, subs, opts);
    }

    getBondById(bondId: number): Observable<Bond> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Bond>(environment.baseUrl + `bonds/getbondbyid/${bondId}`, opts);
    }

    getBondByNumber(number: string): Observable<Bond> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Bond>(environment.baseUrl + `bonds/getbondbynumber/${number}`, opts);
    }

    getBondInvoices(bondId: number): Observable<InvoiceBanksBonds[]> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<InvoiceBanksBonds[]>(environment.baseUrl + `bonds/getinvoices/${bondId}`, opts);
    }

    getBonds(bondsFilter: BondsFilter): Observable<Bond[]> {
        const opts = this.sharedService.getOptsJson();

        bondsFilter.fromDateIssued != null ? bondsFilter.fromDateIssued =
            this.commonFunctions.formatDateMmddyyyy(bondsFilter.fromDateIssued.toString()) : bondsFilter.fromDateIssued = null;
        bondsFilter.toDateIssued != null ? bondsFilter.toDateIssued =
            this.commonFunctions.formatDateMmddyyyy(bondsFilter.toDateIssued.toString()) : bondsFilter.toDateIssued = null;
        bondsFilter.fromDateCanceled != null ? bondsFilter.fromDateCanceled =
            this.commonFunctions.formatDateMmddyyyy(bondsFilter.fromDateCanceled.toString()) : bondsFilter.fromDateCanceled = null;
        bondsFilter.toDateCanceled != null ? bondsFilter.toDateCanceled =
            this.commonFunctions.formatDateMmddyyyy(bondsFilter.toDateCanceled.toString()) : bondsFilter.toDateCanceled = null;

        const body = JSON.stringify(bondsFilter);

        return this.http.post<Bond[]>(environment.baseUrl + `bonds`, body, opts);
    }

    getBondsByKeyword(number: string): Observable<Bond[]> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Bond[]>(environment.baseUrl + `bonds/getbondsbykeyword/${number}`, opts);
    }

    removeBond(id: number): Observable<boolean> {
        const opts = this.sharedService.getOptsJson();
        return this.http.delete<boolean>(environment.baseUrl + `bonds/removeBond/${id}`, opts);
    }


    updateBond(blcupdate: Bond): Observable<Bond> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(blcupdate);
        return this.http.put<Bond>(environment.baseUrl + `bonds/updateBond?`, body, opts);
    }

    updateBondRate(updateBondRateParam: UpdateBondRateParam): Observable<void> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(updateBondRateParam);
        return this.http.put<void>(environment.baseUrl + `bonds/updatebondrate`, body, opts);
    }
}
