import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

import { ReportColumn } from '../../../models';

@Component({
    selector: 'app-report-results',
    templateUrl: './report-results.component.html',
    styleUrls: ['./report-results.component.css']
})

export class ReportResultsComponent implements OnInit {
    @Input() cols: ReportColumn[] = [];
    @Input() filteredCount: any[] = [];
    @Input() isColumnsSortable: boolean = false;
    @Input() reportSubTitle = '';
    @Input() reportTitle = '';
    @Input() results: any[] = [];
    @Input() showExportButton: boolean = true;
    @Input() showNoResultsMessage: boolean;

    @Output() exportEvent = new EventEmitter();
    @Output() linkClickEvent = new EventEmitter();
    @Output() modalBankEvent = new EventEmitter();
    @Output() modalEvent = new EventEmitter();

    exportFileName: string = '';
    exportHeader: string[] = [];
    finalFooterString: string = '';
    finalFooterValue: number = 0;
    formattedExportList = [];
    isLoading: boolean = false;
    isNaN: Function = Number.isNaN;
    reportType: string = '';
    resultSetFooter: any;

    constructor(private primengConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
    }

    export() {
        this.exportEvent.emit();
    }

    isNumber(val: any): boolean {
        return typeof val === 'number';
    }

    onExportClick() {
        this.export();
    }

    onBankModalClick(event: any, pTable: any): void {
        pTable?.reset();
        this.modalBankEvent.emit({ data: event });
    }

    onBlcModalClick(event: any, pTable: any): void {
        pTable?.reset();
        this.modalEvent.emit({ data: event });
    }

    onLinkClick(event: any, pTable: any): void {
        this.linkClickEvent.emit({ data: event.target.id });
    }

    onModalClick(event: any, pTable: any): void {
        pTable?.reset();
        this.modalEvent.emit({ data: event.target.id });
    }

    reportFieldHasValue(value: any, kw: string): boolean {
        return (value?.toString().includes(kw)) ? true : false;
    }
}
