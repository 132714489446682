export class ExportSearchResult {
    number: number;
    company: string;
    community: string;
    bank: string;
    beneficiary: string;
    bondType: string;
    currentAmount: string;
    dateIssued: string;
    dateCanceled: string;
}
