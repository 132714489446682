import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AmountSubType } from '../../models';
import { environment } from '../../../src/environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class AmountSubTypeService {

    private cachedAmountSubTypes: Observable<AmountSubType[]> = null;

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getAmountSubTypes(): Observable<AmountSubType[]> {
        if (!this.cachedAmountSubTypes) {
            const opts = this.sharedService.getOptsApplication();
            this.cachedAmountSubTypes = this.http.get<AmountSubType[]>(environment.baseUrl + `amountSubTypes`, opts);
        }

        return this.cachedAmountSubTypes;
    }

    dropCache(): void {
        this.cachedAmountSubTypes = null;
    }
}
