import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BankAmountType } from '../../models';
import { environment } from '../../../src/environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class BankAmountTypeService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    addBankAmountType(bankAmountTypeParam: BankAmountType): Observable<number> {
        const body = JSON.stringify(bankAmountTypeParam);
        const opts = this.sharedService.getOptsJsonUtf8();
        return this.http.post<number>(`${environment.baseUrl}bankamounttypes/create`, body, opts);
    }

    getBankAmountTypeByBank(bankId: number): Observable<BankAmountType[]> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<BankAmountType[]>(environment.baseUrl + `bankamounttypes/${bankId}`, opts);
    }
}
