import { Component, OnInit } from '@angular/core';

import { AuthorizationService } from '../services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    idleTime: number = 3600000;
    time: any;
    username: string;

    constructor(public authorizationService: AuthorizationService) { }

    ngOnInit(): void {
        // window events
        window.addEventListener('scroll', () => { this.resetTimer(); }, { once: false, passive: false });
        window.addEventListener('load', () => { this.resetTimer(); }, { once: false, passive: false });

        // DOM Events
        document.addEventListener('scroll', () => { this.resetTimer(); }, { once: false, passive: false });
        document.addEventListener('click', () => { this.resetTimer(); }, { once: false, passive: false });
        document.addEventListener('keydown', () => { this.resetTimer(); }, { once: false, passive: false });
        document.addEventListener('mousedown', () => { this.resetTimer(); }, { once: false, passive: false });
        document.addEventListener('mousemove', () => { this.resetTimer(); }, { once: false, passive: false });
        document.addEventListener('touchstart', () => { this.resetTimer(); }, { once: false, passive: false });
    }

    resetTimer(): void {
        const inactiveMessage = `You have been logged out due to inactivity!`;
        clearTimeout(this.time);

        this.time = setTimeout(() => {
            alert(inactiveMessage);
            this.authorizationService.login();
        }, this.idleTime);
    }
}
