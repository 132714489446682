import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Company } from '../../models';
import { environment } from '../../../src/environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class CompanyService {

    private cachedCompanies: Observable<Company[]> = null;

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getCompanies(): Observable<Company[]> {
        if (!this.cachedCompanies) {
            const opts = this.sharedService.getOptsApplication();
            this.cachedCompanies = this.http.get<Company[]>(environment.baseUrl + 'companies', opts);
        }

        return this.cachedCompanies;
    }

    dropCache(): void {
        this.cachedCompanies = null;
    }
}
