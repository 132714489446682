import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';

import { CombinedDetailStatusEnum, CombinedDetailStatusToLabelMapping } from '../../../models/enums';
import { CommonFunctions } from '../../../functions';
import { AmountType, CombinedDetailExportResult, CombinedDetailParam, CombinedDetailSprocResult, Company } from '../../../models';
import { AmountTypeService, AuthorizationService, CompanyService, ExcelService, ReportService } from '../../../services';
import { BlcDatePipe } from '../../../../src/pipes';

@Component({
    selector: 'app-combined-detail',
    templateUrl: './combined-detail.component.html',
    styleUrls: ['./combined-detail.component.css']
})

export class CombinedDetailComponent implements OnInit {
    amountTypeList: AmountType[] = [];
    CombinedDetailStatusToLabelMapping = CombinedDetailStatusToLabelMapping;
    companyListSource: Company[] = [];
    companyListTarget: Company[] = [];
    exportHeader: CombinedDetailExportResult = new CombinedDetailExportResult();
    exportResults: CombinedDetailExportResult[] = [];
    isValidParams: boolean = true;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    reportParameter: CombinedDetailParam = new CombinedDetailParam();
    sprocResults: CombinedDetailSprocResult[] = [];
    statusTypes: (string | CombinedDetailStatusEnum)[];

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private blcDatePipe: BlcDatePipe,
        private commonFunctions: CommonFunctions,
        private companyService: CompanyService,
        private currencyPipe: CurrencyPipe,
        private excelService: ExcelService,
        private messageService: MessageService,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess(false);
        this.reportParameter = new CombinedDetailParam();

        this.statusTypes = Object.values(CombinedDetailStatusEnum);

        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occurred',
                    detail: 'An error occurred getting Amount Types.'
                });
            });

        this.companyService.getCompanies()
            .subscribe((result: Company[]) => {
                this.companyListSource = result;
                this.companyListSource = this.commonFunctions.sortAndFormatCompanies(this.companyListSource);
            });

        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    exportExcel(): void {
        // Set export file name
        const exportFileName = 'CombinedDetail';
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');
        let isBoldRow = false;

        // Add Data
        this.exportResults.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 5: //Issue Date
                    case 6: //Expire Date
                        if (val?.toString() && !val?.toString().includes('Date')) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }));

            // Check if it is the table title row
            if (row.getCell(2).value !== null) {
                if (row.getCell(2).value.toString().includes('Detail Report')) {
                    isBoldRow = true;
                    const rownumber = row._number;
                    worksheet.mergeCells('B' + rownumber + ':K' + rownumber);

                    worksheet.getCell('B' + rownumber).alignment = { vertical: 'middle', horizontal: 'center' };

                    //Set font, size and style in title row
                    row.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };
                }
            }

            // Check if it is a header row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value?.toString().includes('Company') && row.getCell(2).value?.toString().includes('Community')) {
                    // Wrap and align the header text
                    row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                    // Set header row height
                    row.height = 60;

                    // Fill and Border Headers
                    row.eachCell((cell) => {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'D3D3D3' },
                            bgColor: { argb: '' }
                        };
                        cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
                        cell.font = { weight: 'bold' };
                    });

                    // Set column widths to fix header row
                    worksheet.columns.forEach((column) => {
                        column.width = 20;
                    });

                }
            }

            // Check if it is a total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Totals:')) {
                    isBoldRow = true;
                    // Set wrap text
                    row.eachCell((cell) => {
                        cell.alignment = { wrapText: false };
                    });
                }
            }

            // Check it is average row
            if (row.getCell(9).value !== null) {
                if (row.getCell(9).value.toString().includes('Average')) {
                    isBoldRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If total row, set to bold
                if (isBoldRow) {
                    cell.font = { bold: true };
                }

                // If negative value, set font to red
                if (typeof cell.value === 'string' && cell.value?.toString().includes('<') && cell.value?.toString().includes('>')) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                }
            });

            if (isBoldRow) {
                isBoldRow = false;
            }
        });

        this.excelService.exportAsExcelFile(workbook, exportFileName);
    }

    onAsOfDateChange(event: any): void {
        if (event.target?.value === 'mm/dd/yyyy') {
            this.reportParameter.asOfDate == null;
        }

        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onExport(): void {
        try {
            this.sprocResults = [];
            this.exportResults = [];

            this.setDatesToUtc();
            const validatedParams = this.validateReportParams(this.companyListTarget, this.reportParameter);

            for (const validatedItem of validatedParams) {
                if (validatedItem.isValid === false) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Entry Validation Error Occurred',
                        detail: validatedItem.message
                    });
                    return;
                }
            }

            this.exportHeader = {
                company: 'Company',
                community: 'Community',
                bank: 'Bank/Surety',
                status: 'Status',
                bondNumber: 'Bond/LC #',
                dateIssued: 'Issue Date',
                dateExpired: 'Expire Date',
                beneficiary: 'Beneficiary',
                amountTypeAndDescription: 'Bond/LC Type -- Description',
                amount: 'Amount',
                rate: 'Rate',
                isAutoRenew: 'Auto Renew'
            };

            const observableList = [];
            this.reportParameter.companyNumbers = [];
            for (const oitem of this.companyListTarget) {
                if ((oitem.marketNumber) || oitem.marketNumber != null) {
                    this.reportParameter.companyNumbers.push(oitem.marketNumber);
                }
            }

            observableList.push(this.reportService.getCombinedDetailReport(this.reportParameter));
            forkJoin(observableList)
                .subscribe((res: any[]) => {
                    // Clean out non-selected status (if status is selected)
                    this.sprocResults = res[0];
                    if (this.reportParameter.status != 'All') {
                        this.sprocResults = this.sprocResults.filter(result => result.status == this.reportParameter.status);
                    }

                    // Format the date objects to remove timestamps
                    this.sprocResults.forEach((result) => {
                        this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
                    });

                    // Set full report vars before breaking it down
                    const reportResultSetLength: number = this.sprocResults.length;
                    let reportTotalAmount: number = 0;
                    let reportAverageAmount: number = 0;
                    const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

                    // Break up results by company
                    const companyGroups = this.commonFunctions.groupBy(this.sprocResults, `company`);
                    if (Object.keys(companyGroups).length > 0) {
                        // For each company in resultset
                        for (const compName in companyGroups) {
                            const companyReport = companyGroups[compName];
                            // Set company specific variables
                            const companyResultSetLength: number = companyReport.length;
                            let companyTotalAmount: number = 0;
                            let companyAverageAmount: number = 0;
                            // Break up company resultset by if letter of credit
                            const letterOfCreditGroups = this.commonFunctions.groupBy(companyReport, `isLetterOfCredit`);
                            if (Object.keys(letterOfCreditGroups).length > 0) {
                                // For each side of the bool of isLetterOfCredit, basically true and false
                                // This is the most broken down part of the report, and this is where the tables begin being built
                                for (const isLetterOfCreditString in letterOfCreditGroups) {
                                    // Set table specific variables
                                    const currentResultSet: CombinedDetailSprocResult[] = letterOfCreditGroups[isLetterOfCreditString];
                                    const currentResultSetLength: number = currentResultSet.length;
                                    let currentTotalAmount: number = 0;
                                    let currentAverageAmount: number = 0;

                                    // Set table titles and add column headers
                                    if (isLetterOfCreditString == 'false') {
                                        this.exportResults.push({
                                            company: null,
                                            community: `Bond Combined Detail Report for ${currentResultSet[0]?.company.trim()} as of ${this.blcDatePipe.transform(this.reportParameter.asOfDate)}`,
                                            bank: null,
                                            status: null,
                                            bondNumber: null,
                                            dateIssued: null,
                                            dateExpired: null,
                                            beneficiary: null,
                                            amountTypeAndDescription: null,
                                            amount: null,
                                            rate: null,
                                            isAutoRenew: null
                                        });
                                    } else {
                                        this.exportResults.push({
                                            company: null,
                                            community: `Letter of Credit Combined Detail Report for ${currentResultSet[0]?.company.trim()} as of ${this.blcDatePipe.transform(this.reportParameter.asOfDate)}`,
                                            bank: null,
                                            status: null,
                                            bondNumber: null,
                                            dateIssued: null,
                                            dateExpired: null,
                                            beneficiary: null,
                                            amountTypeAndDescription: null,
                                            amount: null,
                                            rate: null,
                                            isAutoRenew: null
                                        });
                                    }
                                    this.exportResults.push(this.exportHeader);

                                    // For each row in specific table
                                    for (const index in currentResultSet) {
                                        const dataRow = currentResultSet[index];
                                        const formattedResult: CombinedDetailExportResult = {
                                            company: dataRow.company,
                                            community: dataRow.community,
                                            bank: dataRow.bank,
                                            status: dataRow.status,
                                            bondNumber: dataRow.bondNumber,
                                            dateIssued: dataRow.dateIssued,
                                            dateExpired: dataRow.dateExpired,
                                            beneficiary: dataRow.beneficiary,
                                            amountTypeAndDescription: dataRow.amountType + ' - ' + dataRow.amountDescription,
                                            amount: +dataRow.amount > 0 ? '$' + (+dataRow.amount).toLocaleString('en', decimalFormat) : `$0.00`,
                                            rate: dataRow.rate ? (+dataRow.rate).toLocaleString('en', decimalFormat) : `0.00`,
                                            isAutoRenew: dataRow.isAutoRenew ? 'Y' : 'N'
                                        }
                                        this.exportResults.push(formattedResult);

                                        // Add to current table total 
                                        currentTotalAmount += +dataRow.amount;
                                    } // End current table for each
                                    // Build out table total row
                                    // if (isLetterOfCreditString == 'false') {
                                    const tableTotalResult: CombinedDetailExportResult = {
                                        company: isLetterOfCreditString == 'false' ? `${currentResultSet[0].company.trim()} - Bond Totals:` : `${currentResultSet[0].company.trim()} - Letter of Credit Totals:`,
                                        community: null,
                                        bank: null,
                                        status: null,
                                        bondNumber: isLetterOfCreditString == 'false' ? `# of Bonds ${currentResultSetLength}` : `# of LCs ${currentResultSetLength}`,
                                        dateIssued: null,
                                        dateExpired: null,
                                        beneficiary: null,
                                        amountTypeAndDescription: 'Total',
                                        amount: '$' + (currentTotalAmount).toLocaleString('en', decimalFormat),
                                        rate: null,
                                        isAutoRenew: null
                                    }
                                    this.exportResults.push(tableTotalResult);

                                    // Calc current table average
                                    currentAverageAmount = currentTotalAmount / currentResultSetLength;

                                    // Build out table average row
                                    const tableAverageResult: CombinedDetailExportResult = {
                                        company: null,
                                        community: null,
                                        bank: null,
                                        status: null,
                                        bondNumber: null,
                                        dateIssued: null,
                                        dateExpired: null,
                                        beneficiary: null,
                                        amountTypeAndDescription: 'Average Amount',
                                        amount: '$' + (currentAverageAmount).toLocaleString('en', decimalFormat),
                                        rate: null,
                                        isAutoRenew: null
                                    }
                                    this.exportResults.push(tableAverageResult);

                                    // Add to company total
                                    companyTotalAmount += currentTotalAmount;
                                } // End isLetterOfCredit foreach

                                // Calc company average amount
                                companyAverageAmount = companyTotalAmount / companyResultSetLength;

                                // Add to report total
                                reportTotalAmount += companyTotalAmount;

                                // Build out and add company total row 
                                const companyTotalResult: CombinedDetailExportResult = {
                                    company: `${companyReport[0].company.trim()} - Grand Totals:`,
                                    community: null,
                                    bank: null,
                                    status: null,
                                    bondNumber: `# ${companyResultSetLength}`,
                                    dateIssued: null,
                                    dateExpired: null,
                                    beneficiary: null,
                                    amountTypeAndDescription: 'Total',
                                    amount: '$' + (companyTotalAmount).toLocaleString('en', decimalFormat),
                                    rate: null,
                                    isAutoRenew: null
                                }
                                this.exportResults.push(companyTotalResult);

                                // Build out and add company average row
                                const companyAverageResult: CombinedDetailExportResult = {
                                    company: null,
                                    community: null,
                                    bank: null,
                                    status: null,
                                    bondNumber: null,
                                    dateIssued: null,
                                    dateExpired: null,
                                    beneficiary: null,
                                    amountTypeAndDescription: 'Average Amount',
                                    amount: '$' + (companyAverageAmount).toLocaleString('en', decimalFormat),
                                    rate: null,
                                    isAutoRenew: null
                                }
                                this.exportResults.push(companyAverageResult);
                            }
                        } // End company foreach
                        // Calc report average row
                        reportAverageAmount = reportTotalAmount / reportResultSetLength;

                        // Build out report total row
                        const reportTotalResult: CombinedDetailExportResult = {
                            company: 'Report Total - Grand Totals:',
                            community: null,
                            bank: null,
                            status: null,
                            bondNumber: `# ${reportResultSetLength}`,
                            dateIssued: null,
                            dateExpired: null,
                            beneficiary: null,
                            amountTypeAndDescription: 'Total',
                            amount: '$' + (reportTotalAmount).toLocaleString('en', decimalFormat),
                            rate: null,
                            isAutoRenew: null
                        }
                        this.exportResults.push(reportTotalResult);

                        // Build out report average row
                        const reportAverageResult: CombinedDetailExportResult = {
                            company: null,
                            community: null,
                            bank: null,
                            status: null,
                            bondNumber: null,
                            dateIssued: null,
                            dateExpired: null,
                            beneficiary: null,
                            amountTypeAndDescription: 'Average Amount',
                            amount: '$' + (reportAverageAmount).toLocaleString('en', decimalFormat),
                            rate: null,
                            isAutoRenew: null
                        }
                        this.exportResults.push(reportAverageResult);
                    } else { // Else no results for specified companies
                        this.exportResults.push({
                            company: `No matches found.`,
                            community: null,
                            bank: null,
                            status: null,
                            bondNumber: null,
                            dateIssued: null,
                            dateExpired: null,
                            beneficiary: null,
                            amountTypeAndDescription: null,
                            amount: null,
                            rate: null,
                            isAutoRenew: null
                        });
                    }
                    // Ship all of these formatted results into an excel workbook
                    this.exportExcel();
                }); // End of subscribe
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Report Layout Error Occurred',
                detail: `There were error(s) occurred during report layout process.`
            });
        }
    }

    onInputChange(eventValue: any, inputName: any) {
        if ((inputName == 'fromAmount' || inputName == 'toAmount') && eventValue > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: (inputName == 'fromAmount' ? 'From Amount' : 'To Amount') + ' must not exceed ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });

            if (inputName == 'fromAmount') {
                this.reportParameter.fromAmount = 0;
            } else {
                this.reportParameter.toAmount = 0;
            }
        }
    }

    onMoveSourceToTarget(): void {
        this.companyListTarget.sort((src: Company, tgt: Company) => src.marketNumber?.localeCompare(tgt.marketNumber));
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onMoveTargetToSource(): void {
        this.companyListSource.sort((src: Company, tgt: Company) => src.marketNumber?.localeCompare(tgt.marketNumber));
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onResetSearch(): void {
        this.companyListSource.push(...this.companyListTarget);
        this.companyListSource.sort((src: Company, tgt: Company) => src.marketNumber?.localeCompare(tgt.marketNumber));
        this.companyListTarget = [];

        this.reportParameter = new CombinedDetailParam();
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    setDatesToUtc(): void {
        if (this.reportParameter.asOfDate) {
            const utcd = this.blcDatePipe.transform(this.reportParameter.asOfDate);
            this.reportParameter.asOfDate = utcd;
        }
    }

    validateReportParams(cotList: Company[], param: CombinedDetailParam): any[] {
        var retval: any[] = [];

        if (cotList.length < 1) {
            retval.push({
                isValid: false,
                message: `No company found in the Selected Company list box. Select a company.`
            });
        }

        if (param.asOfDate == null) {
            retval.push({
                isValid: false,
                message: `Specify an As Of Date.`
            });
        }

        return retval;
    }
}
