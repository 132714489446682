import { Injectable } from '@angular/core';

import { IStorage } from './interfaces/istorage';

@Injectable({
    providedIn: "root"
})
export class SessionStorage implements IStorage {

    public clear(): void {
        sessionStorage.clear();
    }

    public getItem(key: string): string {
        var result = sessionStorage.getItem(key);
        return result;
    }

    public setItem(key: string, data: string): void {
        sessionStorage.setItem(key, data);
    }

    public removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }
}
