import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoadingIndicatorService } from '../services';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: LoadingIndicatorService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const includeSpinner = (!req.url.includes(`nospinner`)) ? true : false;
        this.requests.push(req);
        this.loaderService.isLoading.next(includeSpinner);

        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);

        if (i >= 0) {
            this.requests.splice(i, 1);
        }

        this.loaderService.isLoading.next(this.requests.length > 0);
    }
}
