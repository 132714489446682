import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { switchMap } from 'rxjs/operators';
import { AutoComplete } from 'primeng/autocomplete';
import { FileUpload } from 'primeng/fileupload';

import { CommonFunctions } from '../../functions';
import {
    AmountSubType,
    AmountType,
    Bank,
    BankAmountType,
    BankWithFinancialGuarantee,
    Bond,
    Community,
    Company,
    DocumentParam,
    User,
} from '../../models';
import { AmountTypeEnum, BLCTypeEnum } from '../../models/enums';
import { BlcDatePipe } from '../../../src/pipes';

import {
    AmountSubTypeService,
    BankAmountTypeService,
    BankService,
    BondService,
    CommunityService,
    DocumentService,
} from '../../services';

@Component({
    selector: 'app-create-bond',
    templateUrl: './create-bond.component.html',
    styleUrls: ['./create-bond.component.css'],
    providers: [MessageService]
})
export class CreateBondComponent implements OnInit, OnDestroy {
    @ViewChild('communityNumber') communityAutocomplete: AutoComplete;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @Input() allAmountSubTypeList: AmountSubType[];
    @Input() amountTypeList: AmountType[];
    @Input() companyList: Company[];
    @Input() displayDialog: boolean = false;
    @Input() fullBanksList: Bank[]
    @Input() isLetterOfCredit: boolean = false;
    @Input() isSearchComponent: boolean = false;
    @Input() user: User;

    @Output() childEvent = new EventEmitter();
    @Output() clearSearch = new EventEmitter();
    @Output() onDialogClose: EventEmitter<any> = new EventEmitter();

    amountTypeListFiltered: AmountType[];
    availableCommitment: any = null;
    bankAmountTypeList: BankAmountType[];
    bankData: BankWithFinancialGuarantee;
    bankOrSuretyId: number = 0;
    bankOrSuretyList: Bank[];
    blcType = BLCTypeEnum;
    bond = new Bond;
    bondType: BLCTypeEnum;
    cachedBondNumber: number = 0;
    communityList: Community[];
    currentAmount: number = 0;
    displayAmountSubTypeList: AmountSubType[];
    documentDescriptionToApply: string;
    fieldsPopulated = false;
    fileLimit: number = 3;
    filteredCommunities: Community[] = [];
    isAmountTypeFinancialGuarantee: boolean = false;
    isAmountTypeSelected: boolean = false;
    isAutoRenew: boolean = false;
    isBondFound: boolean = false;
    isCompanySelected: boolean = false;
    isDataChanged: boolean;
    isSaveReady: boolean = false;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    maxUploadSize: number = 52428800;  //  file size 50 megabytes - 52428800 bytes
    rate: number = null;
    rateMaxAmount: number = 10000; // Equals 100% due to (bps)
    selectedCommunity: Community = null;
    singleBondLimit: string = null;
    sub: any;
    success: boolean = false;
    type: string = '';

    constructor(private amountSubTypeService: AmountSubTypeService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private blcDatePipe: BlcDatePipe,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private communityService: CommunityService,
        private currencyPipe: CurrencyPipe,
        private documentService: DocumentService,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.displayAmountSubTypeList = [];
        this.isSearchComponent = false;

        this.getAllBanks();

        this.getAmountSubTypes();

        this.isDataChanged = false;
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    amountTypeFinancialGuarantee(): void {
        if (this.isAmountTypeFinancialGuarantee) {
            this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
            this.bond.rate = this.bankData.financialGuaranteeRate;
        } else {
            this.availableCommitment = this.bankData.availableCommitment;
            this.bond.rate = this.bankData.rate;
        }
    }

    anyFilesSelected(uploader: any): boolean {
        for (const { } of uploader?.files) {
            return false;
        }
        return true;
    }

    checkAutorenew(autoCheck): void {
        this.isAutoRenew = autoCheck;
    }

    clearSelectedCommunityValue(): void {
        this.communityAutocomplete.value = null;
        this.communityAutocomplete.inputEL.nativeElement.value = null;
        this.bond.communityNumber = null;

        this.selectedCommunity = null;
    }

    closeblcModal(): void {
        this.onDialogClose.emit(this.displayDialog);
        this.isSaveReady = false;
        this.resetForm();
        this.documentDescriptionToApply = null;
    }

    getAllBanks(): void {
        this.bankOrSuretyList = this.fullBanksList;
    }

    getAmountSubTypes(selectedValue: number = 0): void {
        this.isAmountTypeSelected = false;
        this.bond.amountSubTypeId = undefined;

        if (!this.allAmountSubTypeList || this.allAmountSubTypeList.length == 0) {
            this.amountSubTypeService.getAmountSubTypes().subscribe((amountSubTypes) => {
                this.allAmountSubTypeList = amountSubTypes;
                this.allAmountSubTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occurred', detail: 'An error occurred getting Amount Sub Types.' });
            });
        }
        if (selectedValue > 0) {
            this.displayAmountSubTypeList = this.allAmountSubTypeList.filter(sub => sub.amountTypeId.toString() == selectedValue.toString());
            this.isAmountTypeSelected = true;
        } else {
            this.displayAmountSubTypeList = [];
        }
    }

    getBondType(): string {
        if (Number(this.bond.isLetterOfCredit) === 1) {
            this.type = 'Letter of Credit';
        } else {
            this.type = 'Bond';
        }
        return this.type;
    }

    getCurrentAmount(): number {
        if (this.bond.amount != 0) {
            this.currentAmount = Number(this.bond.amount);
        } else {
            this.currentAmount = 0;
        }

        return this.currentAmount;
    }

    getSizeInMegaBytes(file: File): number {
        return file ? file.size / 1000000 : 0;
    }

    onAmountTypeChange(amountType: AmountType): void {
        //  reset the bond initial amount due to amount type change!
        this.resetBondAmount();

        this.isAmountTypeFinancialGuarantee = false;
        const selectedValue = Number(amountType);
        if (selectedValue === AmountTypeEnum.FinancialGuarantee || selectedValue === AmountTypeEnum.Performance) {
            if (selectedValue === AmountTypeEnum.FinancialGuarantee) {
                this.isAmountTypeFinancialGuarantee = true;
            }
            this.bankService.getAvailableCommitment(this.bankOrSuretyId, this.isAmountTypeFinancialGuarantee)
                .subscribe((bankInformation) => {
                    this.bankData = bankInformation;
                    this.amountTypeFinancialGuarantee();
                });

            this.isAmountTypeSelected = false;
            this.getAmountSubTypes(selectedValue);
        } else {
            this.isAmountTypeSelected = false;
            this.displayAmountSubTypeList = [];
            this.bankService.getAvailableCommitment(this.bankOrSuretyId, this.isAmountTypeFinancialGuarantee)
                .subscribe((bankInformation) => {
                    this.bankData = bankInformation;
                    this.availableCommitment = this.bankData.availableCommitment;
                    this.bond.rate = this.bankData.rate;

                });
        }
    }

    onBankChange(value: any): void {
        //  resetting bond initial amount!
        this.resetBondAmount();
        const self = this;
        this.bankOrSuretyList.forEach(function (list) {
            if (list.id === Number(value)) {
                if (self.isAmountTypeFinancialGuarantee) {
                    self.rate = list.financialGuaranteeRate;
                } else {
                    self.rate = list.rate;
                }

                if (list.singleBondLimit != null) {
                    self.singleBondLimit = list.singleBondLimit.toFixed(2);
                } else {
                    self.singleBondLimit = '';
                }
            }
        });

        this.bond.rate = this.rate;

        this.bankOrSuretyId = Number(value);
        //  get this bank's bank amount types
        //  filter the amount types based on the bank selection !
        this.bankAmountTypeService.getBankAmountTypeByBank(this.bankOrSuretyId)
            .subscribe((bat: BankAmountType[]) => {
                this.bankAmountTypeList = bat;
                this.amountTypeListFiltered = [];
                for (const _bat of this.bankAmountTypeList) {
                    const filtered = this.amountTypeList.find(({ id }) => id === _bat.amountTypeId);
                    if (filtered !== null) {
                        this.amountTypeListFiltered.push(filtered);
                    }
                }
            });
        // #437237: we took out the call getAvailableCommitment and opted for force calling the onAmountTypeChange
        // since that also has a call to getAvailableCommitment call already. Needs a var of AmountType to pass
        // the TypeChange Event.
        var selectedAmountType = new AmountType();
        selectedAmountType.id = this.bond.amountTypeId;
        this.onAmountTypeChange(selectedAmountType);
    }

    onClearContent(uploader: any): void {
        uploader?.clear();
    }

    onCommunitySelect(event): void {
        this.selectedCommunity = event && event.value ? event.value : null;
        this.bond.communityNumber = event && event.value ? event.value.communityNumber : null;
    }

    onCompanyChange(value: string): void {
        this.isCompanySelected = false;
        this.clearSelectedCommunityValue();

        this.communityService.getCommunities(value).subscribe((communities) => {
            if (communities?.length > 0) {
                this.communityList = communities;
                this.commonFunctions.sortAndFormatCommunities(this.communityList);
                this.filteredCommunities = this.communityList;
            } else {
                this.communityList = [];
                this.filteredCommunities = [];
            }
            this.isCompanySelected = this.communityList?.length > 0 || this.filteredCommunities?.length > 0 ? true : false;
        });
    }

    onDateExpiredDateChange(): void {
        this.isDataChanged = true;
    }

    onDateIssuedDateChange(): void {
        this.isDataChanged = true;
    }

    onDateMailedDateChange(): void {
        this.isDataChanged = true;
    }

    onDocumentDescriptionKeyup(evt: any): void {
        this.documentDescriptionToApply = evt?.target?.value;
    }

    onFieldChange(): void {
        if (this.bond.number && this.bond.isLetterOfCredit && this.bond.marketNumber &&
            this.bond.bankId && this.bond.amountTypeId && (this.bond.amount >= 0 || this.bond.amount <= 0) && this.bond.beneficiary) {
            this.isSaveReady = true;
        } else {
            this.isSaveReady = false;
        }
    }

    onFileSelect(fileUpload: any): void {
        let resetUpload = false;
        let fileCount = 0;
        let fileSizeTotal = 0;

        fileUpload?.files.forEach((selected: File) => {
            fileSizeTotal = fileSizeTotal + selected.size;
            if (fileSizeTotal > this.maxUploadSize) {
                resetUpload = true;
                this.messageService.add({
                    severity: 'info',
                    summary: 'Size too big information',
                    detail: `All files included thus far totals: ${fileSizeTotal} and is too big.  Max file size is: ${this.maxUploadSize} bytes.`
                });
            }
        });

        for (const { } of fileUpload?.files) {
            fileCount++;
            if (fileCount > this.fileLimit) {
                resetUpload = true;
                this.messageService.add({
                    severity: 'info',
                    summary: 'Too many files per upload',
                    detail: `Too many files selected in this upload.  File limit is: ${this.fileLimit}.`
                });
            }
        }

        if (resetUpload) {
            fileUpload?.clear();
        }
    }

    onFilterCommunity(event: any): void {
        let filtered: any[] = [];
        let query = event.query;

        for (let i = 0; i < this.communityList.length; i++) {
            let community = this.communityList[i];
            let selCommunityFullName = `${community.communityNumber} - ${community.communityName}`;
            if (selCommunityFullName.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(community);
            }
        }

        if (filtered.length > 0) {
            this.filteredCommunities = filtered;
        } else {
            this.filteredCommunities = this.communityList;
        }
    }

    onInputInitialAmount(event): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Initial Amount is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bond.amount = 0;
            return;
        }
        this.bond.amount = event.value;
        this.onFieldChange();
    }

    onRateChange(event): void {
        const value = event.srcElement.value;
        if (!this.commonFunctions.isFloat(value) || Number.parseFloat(value) > this.rateMaxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Rate (bps) is out of range. Please set amount to less than ' + this.rateMaxAmount + '.'
            });
            this.bond.rate = 0;
            this.rate = 0;
            return;
        }
        this.bond.rate = value;
        this.rate = value;
        this.onFieldChange();
    }

    onSubmit(): void {
        // map community autocomplete field to bond
        if (this.selectedCommunity && this.selectedCommunity.communityNumber) {
            this.bond.communityNumber = this.selectedCommunity.communityNumber;
        }
        else{
            this.bond.communityNumber = null;
        }

        this.bond.modifiedBy = this.user.loginname;
        //  cache the bond number to a global var prior to go through
        //  all that async subscribe fns
        this.cachedBondNumber = this.bond.number;
        this.setDatesToUtc();
        if (this.validateFields() && this.validateBank()) {
            this.isBondFound = false;

            if (!this.isBondFound) {
                this.bondService.getBondByNumber(this.bond.number.toString())
                    .pipe(
                        switchMap((data: Bond) => {
                            if (data['id'] != 0) {
                                this.isBondFound = true;
                                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'A bond or letter of credit with this number already exists.  Please choose a different Bond/LC number.' });
                                return null;
                            } else {
                                return this.bondService.addBond(this.bond);
                            }
                        })
                    ).subscribe(() => {
                        this.messageService.add({
                            severity: 'success', summary: 'Success', detail: 'The ' + this.type.toLowerCase() + ' has been successfully added'
                        });
                    }, () => {
                        this.messageService.add({
                            severity: 'error', summary: 'Error',
                            detail: 'An error occured adding ' + this.type.toLowerCase()
                        });
                    }, async () => {
                        this.fileUpload.upload();
                        this.fileUpload?.clear();
                        this.closeblcModal();
                    });
            }
        }
    }

    onTypeChange(value: string): void {
        if (value === '0') {
            this.isLetterOfCredit = false;
        } else {
            this.isLetterOfCredit = true;
        }
        this.bond.bankId = undefined;
        this.bankData = undefined;
        this.rate = null;
        this.singleBondLimit = undefined;
        this.availableCommitment = undefined;
        this.amountTypeListFiltered = [];
        this.displayAmountSubTypeList = [];
        this.bond.amountTypeId = undefined;
        this.bond.amountSubTypeId = undefined;

        this.bankOrSuretyList = this.fullBanksList.filter(bank => bank.isSurety == !this.isLetterOfCredit);
        this.sortAndFilterBankSuretyList();
    }

    async removeFile(file: File, fileUpload: any, event: Event): Promise<void> {
        const index = fileUpload?.files.indexOf(file);
        fileUpload?.remove(event, index);
    }

    resetForm(): void {
        this.amountTypeListFiltered = [];
        this.displayAmountSubTypeList = [];
        this.availableCommitment = null;
        this.bankAmountTypeList = [];
        this.bond = new Bond;
        this.communityList = [];
        this.fileUpload?.clear();
        this.isAmountTypeSelected = false;
        this.isAmountTypeFinancialGuarantee = false;
        this.isAutoRenew = false;
        this.isCompanySelected = false;
        this.isSearchComponent = false;
        this.singleBondLimit = null;
        this.clearSelectedCommunityValue();
        this.getAllBanks();
        this.clearSearch.emit();
    }

    resetBondAmount(): void {
        this.bond.amount = null;
        this.getCurrentAmount();
    }

    setDatesToUtc(): void {
        if (this.bond.dateCanceled) {
            const utcd = this.blcDatePipe.transform(this.bond.dateCanceled);
            this.bond.dateCanceled = utcd;
        }

        if (this.bond.dateExpired) {
            const utcd = this.blcDatePipe.transform(this.bond.dateExpired);
            this.bond.dateExpired = utcd;
        }

        if (this.bond.dateIssued) {
            const utcd = this.blcDatePipe.transform(this.bond.dateIssued);
            this.bond.dateIssued = utcd;
        }

        if (this.bond.dateMailed) {
            const utcd = this.blcDatePipe.transform(this.bond.dateMailed);
            this.bond.dateMailed = utcd;
        }
    }

    showSelectedValue(selected: Community): string {
        if (selected) {
            return selected.communityNumber + ' - ' + selected.communityName;
        }
    }

    sortAndFilterBankSuretyList(): void {
        // First, sort by name
        this.bankOrSuretyList?.sort((a, b) => a.name.localeCompare(b.name));

        // Then, put all inactive at the bottom of the list
        this.bankOrSuretyList?.sort((a, b) => {
            return Number(b.isActive) - Number(a.isActive);
        });

        // Now, filter out unncessary records
        if (this.bond.isLetterOfCredit?.toString() != '-1') {
            const filteredBanks: Bank[] = [];
            if (this.bond.isLetterOfCredit?.toString() == '1') {
                this.bankOrSuretyList?.forEach(function (rec) {
                    if (!rec.isSurety) {
                        filteredBanks.push(rec);
                    }
                });
            } else {
                this.bankOrSuretyList?.forEach(function (rec) {
                    if (rec.isSurety) {
                        filteredBanks.push(rec);
                    }
                });
            }

            this.bankOrSuretyList = filteredBanks;
        }
    }

    async uploadFile(event: any, description: any): Promise<void> {
        //  We turn around and get the unique BLC just created to use it for document creations if any!
        const fileDescription = description.value;
        const newBondNumber = `${this.cachedBondNumber}`;
        let newBond: Bond = null;
        this.bondService.getBondByNumber(newBondNumber)
            .subscribe((rtnData: Bond) => {
                newBond = rtnData;
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: `Unexpected Error Occurred`,
                    detail: `Unable to get the Bond ID for Bond Number: ${newBondNumber}`
                });
            }, () => {
                if ((newBond == undefined) || (newBond?.id < 1)) {
                    this.messageService.add({
                        severity: 'error',
                        summary: `Unexpected Bond Creation Result.`,
                        detail: 'Bond or Letter of Credit record not created therefore documents (if any) will not be uploaded.'
                    });
                    return;
                }

                try {
                    for (const file of event?.files) {
                        this.commonFunctions.getByteArray(file)
                            .then((value: ArrayBuffer) => {
                                const newDocument = new DocumentParam();
                                const byteArr = new Uint8Array(value);
                                const base64 = this.commonFunctions.convertToBase64(byteArr);
                                newDocument.base64Content = base64;
                                newDocument.blobName = file.name;
                                newDocument.documentId = this.commonFunctions.generateUuid();
                                newDocument.fileDescription = fileDescription;
                                newDocument.fileType = 'application/pdf';
                                newDocument.uploadBy = this.user.loginname;
                                newDocument.blcId = newBond.id;
                                newDocument.contentType = 'application/pdf';
                                this.documentService.uploadDocumentToBlobContainer(newDocument)
                                    .subscribe((result: Boolean) => {
                                        if (result) {
                                            this.messageService.add({
                                                severity: 'success',
                                                summary: `Upload And Create Document Success.`,
                                                detail: `${newDocument.blobName} uploaded successfully.`
                                            });
                                        }
                                    });
                            });
                    }
                } catch (error) {
                    this.messageService.add({
                        severity: 'error',
                        summary: `Unexpected Error Occurred`,
                        detail: `An error occurred uploading a document in Azure blob.`
                    });
                } finally {
                    if (event && event.clear){
                        event.clear();
                    }
                    if (description) {
                        description.value = null;
                    }
                }
            });
    }

    validateBank(): boolean {
        if (this.bond.bankId != 0) {
            this.currentAmount = this.getCurrentAmount();
            this.type = this.getBondType();

            if (this.currentAmount > Number(this.availableCommitment)) {
                this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'The currently selected bank or surety does not have enough available commitment to service this ' + this.type.toLowerCase() + '.' });
                this.success = true;
            } else if (this.currentAmount > Number(this.singleBondLimit)) {
                this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'The currently selected bank or surety has a single bond limit less than the current amount for this ' + this.type.toLowerCase() + '.' });
                this.success = true;
            } else {
                this.success = true;
            }
        }

        return this.success;
    }

    validateFields(): boolean {
        const expiredDate = new Date(this.bond.dateExpired);
        const issuedDate = new Date(this.bond.dateIssued);

        if (this.bond.isLetterOfCredit == undefined) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the Type.' });
            return false;
        }

        if (this.bond.marketNumber == undefined) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the company.' });
            return false;
        }

        if (this.bond.bankId == undefined) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the bank.' });
            return false;
        }

        if (this.bond.amountTypeId == undefined) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the bond type.' });
            return false;
        }

        if ((Number(this.bond.amountTypeId) === 1 || Number(this.bond.amountTypeId) === 2) && this.bond.amountSubTypeId == undefined) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the bond subtype.' });
            return false;
        }

        if (this.bond.amount <= 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a valid initial amount.' });
            return false;
        }
        if (!this.bond.amount) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the initial amount.' });
            return false;
        }

        if (!this.bond.rate) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the rate.' });
            return false;
        }

        if (!this.bond.beneficiary) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the beneficiary.' });
            return false;
        }

        if (this.bond.dateIssued && !this.bond.number) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the bond number.' });
            return false;
        }

        if (this.bond.dateIssued && this.bond.number && !this.bond.dateExpired) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the expire date.' });
            return false;
        }

        if (this.bond.dateExpired && this.bond.dateIssued && expiredDate <= issuedDate) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'The Date Issued must preceed the Expiration Date' });
            return false;
        }

        return true;
    }
}
