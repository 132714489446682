import { Injectable, Injector } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { STORAGE } from './app.constants';

@Injectable({
    providedIn: "root"
})
export class BLCCryptography {
    private key: string;

    constructor(private injector: Injector) {
        this.key = this.md5Hash(this.injector.get(STORAGE));
    }

    encrypt(data: any): string {
        return CryptoJS.AES.encrypt(data, this.key).toString();
    }

    decrypt(data: any): string {
        return CryptoJS.AES.decrypt(data, this.key).toString(CryptoJS.enc.Utf8);
    }

    shaHash(key: string): string {
        var key = CryptoJS.SHA256(key, this.key);
        return key.toString();
    }

    md5Hash(key: string): string {
        var hashedKey = CryptoJS.MD5(key);
        return hashedKey.toString();
    }
}
