import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lib-unassigned',
    templateUrl: './unassigned.component.html',
    styleUrls: ['./unassigned.component.css']
})
export class UnassignedComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
