export class TotalRowData {
    totalCommitmentAmount: number = 0;
    totalFGCommitment: number = 0;
    totalOsTotal: number = 0;
    totalRate: number = 0;
    totalFgRate: number = 0;
    totalRecords: number = 0;
    totalFgrRecords: number = 0;
    get averageTotalRate() { return this.totalRate / this.totalRecords; }
    get averageTotalFgRate() { return this.totalFgRate / this.totalFgrRecords; }
    get averageBondPricing() { return ((this.totalRate / this.totalRecords * 0.9) + (this.totalFgRate / this.totalFgrRecords * 0.1)); }
}
