import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../models';

@Pipe({ name: 'companyName' })
export class CompanyNamePipe implements PipeTransform {
    transform(marketNumber: string, companyList: Company[]): string {
        if (companyList?.length > 0) {
            const result = companyList.find(element => element.marketNumber === marketNumber);
            return result?.marketNumber + ' - ' + result?.companyName;
        }

        return null;
    }
}
