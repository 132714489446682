import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthorizationService } from '../services';

@Injectable()
export class AuthGuard {
    constructor(private authorizationService: AuthorizationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authorizationService.getAccessToken() === null) {
            this.router.navigate(['unauthorized']);
            return false;
        }

        if (this.authorizationService.authenticated() === false) {
            this.router.navigate(['unauthenticated']);
            return false;
        }

        if (this.authorizationService.isInRole() === false) {
            this.router.navigate(['unassigned']);
            return false;
        }

        return true;
    }
}
