// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.well {
    border: solid 1px #d3d3d3;
    margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/reports/combined-summary/combined-summary.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".well {\r\n    border: solid 1px #d3d3d3;\r\n    margin: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
