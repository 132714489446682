export class Bank {
    id: number;
    name: string;
    brokerId: number;
    commitAmount: number;
    counterSignatureRequirements: string;
    dateIndemnityAgreement: string | Date;
    description: string;
    financialGuaranteeCommitAmount: number;
    financialGuaranteeRate: number;
    isSelected: boolean;
    isSurety: boolean;
    isActive: boolean;
    isPowerOfAttorney: boolean;
    isRevolver: boolean;
    minimumFee: number;
    rate: number;
    singleBondLimit: number;
    terms: string;
    dateCreated: string | Date;
    dateModified: string | Date;
    modifiedBy: string;
}
