export class BankWithAmountTypes {
    constructor() {
        this.id = null;
        this.name = '';
        this.brokerId = 0;
        this.commitAmount = null;
        this.counterSignatureRequirements = '';
        this.dateIndemnityAgreement = '';
        this.description = '';
        this.financialGuaranteeCommitAmount = null;
        this.financialGuaranteeRate = null;
        this.isSelected = null;
        this.isSurety = null;
        this.isActive = false;
        this.isPowerOfAttorney = false;
        this.isRevolver = false;
        this.minimumFee = null;
        this.rate = null;
        this.singleBondLimit = null;
        this.terms = '';
        this.dateCreated = new Date();
        this.dateModified = new Date();
        this.modifiedBy = 'BLC System';
        this.amountTypes = [];
    }

    id: number;
    name: string;
    brokerId: number;
    commitAmount: number;
    counterSignatureRequirements: string;
    dateIndemnityAgreement: string | Date;
    description: string;
    financialGuaranteeCommitAmount: number;
    financialGuaranteeRate: number;
    isSelected: boolean;
    isSurety: boolean;
    isActive: boolean;
    isPowerOfAttorney: boolean;
    isRevolver: boolean;
    minimumFee: number;
    rate: number;
    singleBondLimit: number;
    terms: string;
    dateCreated: string | Date;
    dateModified: string | Date;
    modifiedBy: string;
    amountTypes: number[];
}
