import { Injectable } from '@angular/core';

import { AmountSubTypeService } from './data/amountSubType.service';
import { AmountTypeService } from './data/amountType.service';
import { BankService } from './data/bank.service';
import { BrokerService } from './data/broker.service';
import { CompanyService } from './data/company.service';

@Injectable()
export class ClientDataCacheService {

    constructor(private amountSubTypeService: AmountSubTypeService,
        private amountTypeService: AmountTypeService,
        private bankService: BankService,
        private brokerService: BrokerService,
        private companyService: CompanyService) { }

    removeAngularCache(cacheName: string): void {
        switch (cacheName.toLowerCase()) {
            case 'all':
                this.amountSubTypeService.dropCache();
                this.amountTypeService.dropCache();
                this.bankService.dropCache();
                this.brokerService.dropCache();
                this.companyService.dropCache();                
                break;
            case 'amountsubtypes':
                this.amountSubTypeService.dropCache();
                break;
            case 'amounttypes':
                this.amountTypeService.dropCache();
                break;
            case 'banks':
                this.bankService.dropCache();
                break;
            case 'brokers':
                this.brokerService.dropCache();
                break;
            case 'companies':
                this.companyService.dropCache();
                break;
        }
    }
}
