export enum AmountSubTypeEnum {
    LandDeposit = 1,
    CustomerDeposit = 2,
    MaintenanceAssessments = 3,
    TaxAssessments = 4,
    MechanicLien = 5,
    FinancialGuarenteeOther = 6,
    LaborAndMaterials = 7,
    LandDevelopmentImprovements = 8,
    PerformanceOther = 9
}
